import Cookies from "js-cookie"
import client from "./client"
import { RequestPostMealParams, RequestUpdateMealParams } from "../interfaces/training_recipe";


export const getServiceTrainingRecipeAll = async () => {
  const res = await client.get("/admin/training_recipes", {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  })
  return res;
}

export const getServiceTrainingRecipe = async (id: number) => {
  const res = await client.get(`/training_recipes/${id}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  })
  return res;
}

export const getPostMeal = (id: number) => {
  return client.get(`/post_meals/${id}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });
}

export const createPostMeal = async (params: RequestPostMealParams, date?: string) => {
  const url = date ? `/post_meals?date=${date}` : '/post_meals'
  return await client.post(url, params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid'),
      "content-type": 'multipart/form-data'
    }
  });
}

export const updatePostMeal = async (params: RequestUpdateMealParams, id: number) => {
  return await client.put(`/post_meals/${id}`, params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid'),
      "content-type": 'multipart/form-data'
    }
  })
}
