interface DayOfWeek {
  [index: number]: string
}

export const dayOfWeek: DayOfWeek = {
  0: '日',
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
} as const;

export const dayTime: Array<number> = [...Array(24)].map((_, i) => i);

export const oneHoursMinutes: Array<number> = [...Array(60 + 1)].map((_, i) => i);

export const weightNumber: Array<number> = [...Array(200 + 1)].map((_, i) => i);

export const weightFloatNumber: Array<number> = [...Array(100)].map((_, i) => i);