import { useContext, useState } from "react";
import AdminLayout from "../../layouts/admin";
import { createEfficacy } from "../../services/efficacy";
import { AuthContext } from "../../App";
import { useNavigate } from "react-router-dom";

const AdminEfficacyCreate = () => {
  const [efficacy, setEfficacy] = useState<string>('');
  const {alert, setAlert} = useContext(AuthContext);
  const navigate = useNavigate();

  const handleCreateEfficacy = async () => {
    try {
      const res = await createEfficacy(efficacy);
      setAlert({ ...alert, open: true, severity: 'success', message: '効能の作成に成功しました。' });
      navigate('/admin/efficacies');
      
    } catch(e) {
      setAlert({ ...alert, open: true, severity: 'error', message: '効能の作成に失敗しました。' });
    }
  }

  return(
    <AdminLayout>
      <div>
        <div>
          <input type="text" onChange={(e) => setEfficacy(e.target.value)} />
          <button
            type="submit"
            onClick={handleCreateEfficacy}
          >作成する</button>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminEfficacyCreate;