import { useState } from "react";
import { ForgotPasswordParams } from "../interfaces/auth";
import { forgotPassword } from "../services/auth";
import { useNavigate } from "react-router-dom";


const ForgotPassword = () => {
  const [ email, setEmail ] = useState('');
  const navigate = useNavigate();

  const handleForgotPasswordSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const params: ForgotPasswordParams = {
      email: email,
      redirectUrl: `${process.env.REACT_APP_BASE_URL}/user/password/reset`
    }

    try {
      const res = await forgotPassword(params);
      navigate("/sign_in");
    } catch (e) {
      console.log(e);
    }
  }

  return(
    <div className="wrapper">
      <main className="page-sign">
        <article>
          <section className="page-heading">
            <div className="m-inner">
              <div className="page-title">
                <span className="gradient">PASSWORD RESET</span>
                <h1>パスワード再発行</h1>
              </div>
            </div>
          </section>
          <section className="page-contents">
            <div className="m-inner">
              <div className="form-box">
                <form>
                  <fieldset>
                    <label>メールアドレス</label>
                    <input type="text" name="email" value={email} onChange={((e) => setEmail(e.target.value))} />
                    <p className="caption caption100">ご登録のメールアドレス宛にパスワード再発行用のリンクを記載したメールをお送りさせていただきます。</p>
                  </fieldset>
                  <fieldset className="submit">
                    <button type="submit" disabled={!email ? true : false} onClick={handleForgotPasswordSubmit}>メールを送る</button>
                  </fieldset>
                </form>
              </div>
              <div className="notice-box">
                <p>登録メールアドレスが分からない場合は下記にお問い合わせください。</p>
                <p><span>【お問い合わせ先】</span><br />support@body-science.co.jp</p>
              </div>
            </div>
          </section>
        </article>
      </main>
    </div>
  );
}

export default ForgotPassword;
