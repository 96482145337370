'use client';

import { useEffect, useState } from "react";
import AdminLayout from "../../../layouts/admin";
import { ResponseTrainingRecipe } from "../../../interfaces/training_recipe";
import { getServiceTrainingRecipeAll } from "../../../services/training_recipe";

const AdminTrainingRecipe = () => {
  const [ trainingRecipes, setTrainingRecipes ] = useState<ResponseTrainingRecipe[]>();

  const getAdminTrainingRecipe = async() => {
    try {
      const res = await getServiceTrainingRecipeAll();
      setTrainingRecipes(res.data);
    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAdminTrainingRecipe()
  }, []);

  return(
    <AdminLayout>
      <div className="text-right">
        <a href="/admin/training_recipe/create" className="admin__create_btn">新規作成</a>
      </div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only">
                Img
              </span>
            </th>
            <th scope="col" className="px-6 py-3">
              タイトル
            </th>
            <th scope="col" className="px-6 py-3">
              Day
            </th>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only">
                Detail
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {trainingRecipes?.map((trainingRecipe) => (
            <>
              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <div className="admin__recipe_img">
                    <img src={trainingRecipe.thumbnailPath} alt="training recipe img" />
                  </div>
                </th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {trainingRecipe.title}
                </th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {trainingRecipe.sortOrder}
                </th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <button
                    type="submit"
                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                  >
                    <p>
                      詳細画面へ
                    </p>
                  </button>
                </th>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </AdminLayout>
  );
}

export default AdminTrainingRecipe;
