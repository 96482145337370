import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useMedia from "use-media";

import { TodoInterface } from './interfaces/todo';
import { User } from "../src/interfaces/auth";
import { getTodo } from './services/todo';
import { getCurrentUser } from "../src/services/auth";

import './App.css';
import SignIn from './pages/sign_in';
import Profile from './pages/profile';
import Todo from './pages/todo';
import Data from './pages/data';
import SignUp from './pages/sign_up';
import ForgotPassword from './pages/forgot_password';
import UserPasswordReset from './pages/user_password_reset';
import SleepInfo from './pages/sleep_info';
import WeightInfo from './pages/weight_info';
import Workout from './pages/workout';
import WorkoutMovie from './pages/workout_movie';
import Recipe from './pages/recipe';
import Eating from './pages/eating';
import AdminTop from './pages/admin';
import AdminPreUsers from './pages/admin/pre_users';
import AdminUsers from './pages/admin/users';
import AdminUserDetail from './pages/admin/users/detail';
import AdminTrainingRecipe from './pages/admin/training_recipe';
import CreateTrainingRecipe from './pages/admin/training_recipe/create';
import { AlertMessageProps } from './interfaces/alert';
import AdminSignIn from './pages/admin/sign_in';
import ErrorPage404 from './pages/404';
import AccountSetting from './pages/account_setting';
import PcAccessRestrictions from './components/PcAccessRestrictions';
import AdminEfficacy from './pages/efficacy';
import AdminEfficacyCreate from './pages/efficacy/create';

export const AuthContext = createContext({} as {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  isSignedIn: boolean
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>
  currentUser: User | undefined
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>
  currentTodo: TodoInterface | undefined
  setCurrentTodo: React.Dispatch<React.SetStateAction<TodoInterface | undefined>>
  alert: AlertMessageProps | undefined
  setAlert: React.Dispatch<React.SetStateAction<AlertMessageProps | undefined>>
});

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [ currentTodo, setCurrentTodo ] = useState<TodoInterface>();
  const [ alert, setAlert ] = useState<AlertMessageProps>();
  const isWide = useMedia({ minWidth: "1000px" });


  const handleGetCurrentUser = async () => {
    try {
      const res = await getCurrentUser();

      if (res?.data.isLogin === true) {
        setIsSignedIn(true);
        setCurrentUser(res?.data.data);
      } else {
        console.log("No current user");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const handleGetTodayTodo = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    try {
      const todoRes = searchParams.get('date') ? await getTodo(searchParams.get('date') ?? '') : await getTodo();

      if (todoRes.status === 200) {
        setCurrentTodo(todoRes.data);
      } else {
        console.log("No Today Todo");
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    handleGetCurrentUser();
    handleGetTodayTodo();
  }, [setCurrentUser]);

  const AdminPrivate = ({ children }: { children: React.ReactElement }) => {
    if (!loading) {
      if (currentUser?.isAdmin) {
        if (isSignedIn) {
          return children;
        } else {
          return <Navigate replace to="/admin/sign_in" />
        }
      } else {
        return <Navigate replace to="/404" />
      }
    }
    else {
      return <></>
    }
  }

  const AdminPublic = ({ children }: { children: React.ReactElement }) => {
    if (!isSignedIn) {
      return children;
    } else {
      return <Navigate replace to="/admin/pre_users" />
    }
  }

  const Private = ({ children }: { children: React.ReactElement }) => {
    if (!loading) {
      if (isSignedIn) {
        return children;
      } else {
        return <Navigate replace to="/sign_in" />
      }
    } else {
      return <></>
    }
  }

  const Public = ({ children }: { children: React.ReactElement }) => {
    if (!loading) {
      if (!isSignedIn) {
        return children;
      } else {
        return <Navigate replace to="/todo" />
      }
    } else {
      return <></>
    }
  }

  return (
    <>
      <BrowserRouter>
        <AuthContext.Provider value={{ loading, setLoading, isSignedIn, setIsSignedIn, currentUser, setCurrentUser, currentTodo, setCurrentTodo, alert, setAlert}}>
          <Routes>
            <Route
              path="/"
              element={
                <Private>
                  {/* TODO: <Profile />に戻す */}
                  {isWide ? (<PcAccessRestrictions />) : (<AccountSetting />)}
                </Private>
              }
            ></Route>
            <Route
              path="/todo"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<Todo />)}
                </Private>
              }
            ></Route>
            <Route
              path="/data"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<Data />)}
                </Private>
              }
            ></Route>
            <Route
              path="/sleep_info"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<SleepInfo />)}
                </Private>
              }
            ></Route>
            <Route
              path="/recipe/:id"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<Recipe />)}
                </Private>
              }
            ></Route>
            <Route
              path="/recipe/:id/eating"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<Eating />)}
                </Private>
              }
            ></Route>
            <Route
              path="/weight_info"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<WeightInfo />)}
                </Private>
              }
            ></Route>
            <Route
              path="/workouts/:id"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<Workout />)}
                </Private>
              }
            ></Route>
            <Route
              path="/workouts/:workout_id/workout_movie/:workout_movie_id"
              element={
                <Private>
                  {isWide ? (<PcAccessRestrictions />) : (<WorkoutMovie />)}
                </Private>
              }
            ></Route>
            {/* 管理画面用のルーティング */}
            <Route path="/admin">
              <Route
                path="dashboard"
                element={
                  <AdminPrivate>
                    <AdminTop />
                  </AdminPrivate>
                }
              >
              </Route>
              <Route
                path="pre_users"
                element={
                  <AdminPrivate>
                    <AdminPreUsers />
                  </AdminPrivate>
                }
              ></Route>

              <Route
                path="users"
                element={
                  <AdminPrivate>
                    <AdminUsers />
                  </AdminPrivate>
                }
              ></Route>

              <Route
                path="users/:id"
                element={
                  <AdminPrivate>
                    <AdminUserDetail />
                  </AdminPrivate>
                }
              ></Route>

              <Route
                path="training_recipe"
                element={
                  <AdminPrivate>
                    <AdminTrainingRecipe />
                  </AdminPrivate>
                }
              ></Route>

              <Route
                path="training_recipe/create"
                element={
                  <AdminPrivate>
                    <CreateTrainingRecipe />
                  </AdminPrivate>
                }
              ></Route>

              <Route
                path="efficacies"
                element={
                  <AdminPrivate>
                    <AdminEfficacy />
                  </AdminPrivate>
                }
              ></Route>

              <Route
                path="efficacies/create"
                element={
                  <AdminPrivate>
                    <AdminEfficacyCreate />
                  </AdminPrivate>
                }
              ></Route>

              <Route
                path="sign_in"
                element={
                  <AdminPublic>
                    <AdminSignIn />
                  </AdminPublic>
                }
              ></Route>

            </Route>
            <Route
              path="/sign_in"
              element={
                <Public>
                  {isWide ? (<PcAccessRestrictions />) : (<SignIn />)}
                </Public>
              } />
            <Route
              path="/sign_up"
              element={
                <Public>
                  {isWide ? (<PcAccessRestrictions />) : (<SignUp />)}
                </Public>
              } />
            <Route
              path="/forgot-password"
              element={
                <Public>
                  {isWide ? (<PcAccessRestrictions />) : (<ForgotPassword />)}
                </Public>
              } />
            <Route
              path="/user/password/reset"
              element={
                <Public>
                  {isWide ? (<PcAccessRestrictions />) : (<UserPasswordReset />)}
                </Public>
              } />
            <Route
              path='*'
              element={
                <ErrorPage404 />
              }
            ></Route>
          </Routes>
        </AuthContext.Provider>
      </BrowserRouter>
    </>
  )
}

export default App;
