import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoadingState {
  value: boolean;
}

const initialState: LoadingState = { value: false };

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.value = true;
    },
    stopLoading: (state) => {
      state.value = false;
    }
  }
});

export const { startLoading, stopLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
