import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AuthContext } from "../App";
import { WorkoutInterface } from "../interfaces/workout";
import { getServiceWorkoutDetail } from "../services/workouts";
import { getTotalTime } from "../utils/index";

import arrowBack from "../assets/images/arrow-back-blk.svg";
import arrow from "../assets/images/check.svg";
import videoCount from "../assets/images/videos-count.svg";
import time from "../assets/images/time.svg";
import playBtn from "../assets/images/play-btn.svg";
import { AppDispatch, RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../components/ Loading";

const Workout = () => {
  const params = useParams();
  const [workoutDetail, setWorkoutDetail] = useState<WorkoutInterface>();
  const { currentTodo } = useContext(AuthContext);
  const searchParams = new URLSearchParams(window.location.search);
  const queryParameter = searchParams.get('date');
  const totalTime = getTotalTime(workoutDetail?.workoutMovies.map((movie) => { return movie.playTime }) ?? []);
  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();

  const getWorkoutDetail = async () => {
    dispatch(startLoading());
    try {
      const res = await getServiceWorkoutDetail(Number(params.id));
      setWorkoutDetail(res.data);
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(stopLoading());
    }
  }

  useEffect(() => {
    getWorkoutDetail();
  }, []);

  return (
    <div>
      {isLoading ? <LoadingComponent /> : (
        <div className="wrapper">
          <main className="page-todo-level01 page-workout">
            <article>
              <section className={currentTodo?.workoutComplete ? "page-heading shadow todo-completed" : "page-heading shadow"}>
                <div className="l-inner head-navi flex f-space a-center">
                  <a href={queryParameter ? `/todo?date=${queryParameter}` : "/todo"} className="btn-back">
                    <figure><img src={arrowBack} /></figure>
                  </a>
                  <div className="checking">
                    <figure><img src={arrow} /></figure>
                  </div>
                </div>
                <div className="m-inner">
                  <div className="sub-info">
                    <p className="cat">トレーニング動画</p>
                    <h2>{workoutDetail?.title}</h2>
                  </div>
                </div>
                <div className="bg bg-cover" style={{ backgroundImage: `url(${workoutDetail?.thumbnailUri})` }}></div>
              </section>

              <section className="contents">
                <div className="l-inner">
                  <div className="overview border shadow">
                    <div className="m-inner">
                      <ul className="flex f-space a-center">
                        <li className="border-s flex f-start a-center">
                          <figure><img src={videoCount} /></figure>
                          <h3>{workoutDetail?.workoutMovies.length}つの動画</h3>
                        </li>
                        <li className="border-s flex f-start a-center">
                          <figure><img src={time} /></figure>
                          <h3>計{totalTime}分</h3>
                        </li>
                      </ul>
                    </div>
                    <div className="text">
                      <div className="m-inner">
                        <p>{workoutDetail?.summary}</p>
                      </div>
                    </div>
                  </div>
                  <ul className="video-list">
                    {workoutDetail?.workoutMovies.map((workoutMovie) => (
                      <li className="border" key={workoutMovie.id}>
                        <a href={queryParameter ? '/workouts/' + workoutDetail.id + '/workout_movie/' + workoutMovie.id + `?date=${queryParameter}` : '/workouts/' + workoutDetail.id + '/workout_movie/' + workoutMovie.id} className="flex f-space">
                          <div className="kv bg" style={{ backgroundImage: `url(${workoutMovie.thumbnailPath})` }}></div>
                          <div className="title">
                            <h3>{workoutMovie.title}</h3>
                            <p>{workoutMovie.playTime}</p>
                            <figure><img src={playBtn} /></figure>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
            </article>
          </main>
        </div>
      )}
    </div>
  );
}

export default Workout;
