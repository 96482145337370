import Cookies from "js-cookie"
import client from "./client"
import Efficacy from "../entities/efficacy"
import { ResponseEfficacies } from "../interfaces/efficacy"

type EfficacyParams = {
  id: number;
  name: string;
}

export const getEfficacyList = async (): Promise<Array<Efficacy>> => {
  const efficacies: Array<Efficacy> = [];
  return client.get<ResponseEfficacies>('/admin/efficacies', {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  }).then((res) => {
    res.data.data.forEach((r) => {
      efficacies.push({
        id: r.id,
        name: r.name,
        createdAt: r.createdAt,
        updatedAt: r.updatedAt
      });
    });

    return efficacies;
  });
}

export const createEfficacy = async (name: string) => {
  return client.post('/admin/efficacies', {name: name}, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });
}

export const updateEfficacy = async (params: EfficacyParams) => {
  return client.put(`/admin/efficacies/${params.id}`, params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });
}