'use client';

import { useEffect, useState } from "react";
import AdminLayout from "../../layouts/admin";
import { ResponsePreUser } from "../../interfaces/user";
import { getPreUser, inviteMultiplePreUser, invitePreUser } from "../../services/user";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../components/utils/AlertMessage";
import { AlertMessageProps } from "../../interfaces/alert";

const AdminPreUsers = () => {
  const [ preUsers, setPreUsers ] = useState<ResponsePreUser[]>();
  const [ alert, setAlert ] = useState<AlertMessageProps>();
  const navigate = useNavigate();

  const getPreUserInfo = async() => {
    try {
      const res = await getPreUser();
      if(res.status === 200) {
        setPreUsers(res.data);
      }
    } catch(e) {
      console.log(e);
    }
  }

  const handleInvite = async (event: React.MouseEvent<HTMLButtonElement>, id: number, email: string) => {
    event.preventDefault();
    const confirm = window.confirm(`${email}\nこのユーザを招待します。\n本当によろしいですか？`);
    if (!confirm) return;
    try {
      const inviteRes = await invitePreUser(id);
      if (inviteRes.status === 200) {
        setAlert({ ...alert, open: true, severity: 'success', message: 'メール送信に成功しました。' });
        navigate('/admin/pre_users')
      }
    } catch(e) {
      console.log(e);
      setAlert({ ...alert, open: true, severity: 'error', message: 'メール送信に失敗しました。' });
    }
  }

  const handleMultipleInvite = async() => {
    try {
      const inviteMultipleRes = await inviteMultiplePreUser();
      if (inviteMultipleRes.status === 200) {
        setAlert({ ...alert, open: true, severity: 'success', message: 'メールの一斉送信に成功しました。' });
        navigate('/admin/pre_users')
      }
    } catch(e) {
      console.log(e);
      setAlert({ ...alert, open: true, severity: 'error', message: 'メール送信に失敗しました。' });
    }
  }

  useEffect(() => {
    getPreUserInfo();
  }, []);

  return(
    <AdminLayout>
      <AlertMessage
        open={alert?.open ?? false}
        severity={alert?.severity ?? ''}
        message={alert?.message ?? ''}
      />
      <div className="pc">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                  <th scope="col" className="px-6 py-3">
                    ユーザー名
                  </th>
                  <th scope="col" className="px-6 py-3">
                    メールアドレス
                  </th>
                  <th scope="col" className="px-6 py-3">
                    性別
                  </th>
                  <th scope="col" className="px-6 py-3">
                    年齢
                  </th>
                  <th scope="col" className="px-6 py-3">
                    招待メール状況
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <button
                      type="submit"
                      className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                      onClick={(e) => handleMultipleInvite()}
                    >一斉にメール送信</button>
                  </th>
              </tr>
            </thead>
            <tbody>
              {preUsers?.map((preUser) => (
                <>
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {preUser.name}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {preUser.email}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {preUser.gender}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {preUser.age}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {preUser.disable ? '送信済み' : '未送信'}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <button
                        type="submit"
                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                        onClick={(e) => handleInvite(e, preUser.id, preUser.email)}
                      >
                        <p>
                          {preUser.disable ? '招待メールの再送信': '招待メールを送る'}
                        </p>
                      </button>
                    </th>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="sp">
        <div className="overflow-x-scroll">
          <div className="admin-table">
          <ul className="flex my-10 admin-tr">
            <li className="fixed01 admin-th">
              ユーザー名
            </li>
            <li className="admin-td" style={{width: "300px"}}>
              メールアドレス
            </li>
            <li className="admin-td">
              性別
            </li>
            <li className="admin-td">
              年齢
            </li>
            <li className="admin-td">
              招待メール状況
            </li>
            <li className="admin-td">
              <button
                type="submit"
                style={{width: "200px"}}
                className="text-base font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                onClick={(e) => handleMultipleInvite()}
              >一斉にメール送信</button>
            </li>
          </ul>
          </div>
          <div className="admin-table">
            {preUsers?.map((preUser) => (
              <ul className="flex my-6 admin-tr" key={preUser.id}>
                <li className="fixed01 admin-th">
                  {preUser.name}
                </li>
                <li className="admin-td" style={{width: "300px"}}>
                  {preUser.email}
                </li>
                <li className="admin-td">
                  {preUser.gender}
                </li>
                <li className="admin-td">
                  {preUser.age}
                </li>
                <li className="admin-td">
                  {preUser.disable ? '送信済み' : '未送信'}
                </li>
                <li className="admin-td">
                  <button
                    type="submit"
                    className="text-base font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                    style={{width: "200px"}}
                    onClick={(e) => handleInvite(e, preUser.id, preUser.email)}
                  >
                    <p>
                      {preUser.disable ? '招待メールの再送信': '招待メールを送る'}
                    </p>
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminPreUsers;
