import { useContext, useEffect, useState } from "react";

import AdminLayout from "../../layouts/admin";
import Efficacy from "../../entities/efficacy";
import AlertMessage from "../../components/utils/AlertMessage";
import { getEfficacyList, updateEfficacy } from "../../services/efficacy";
import { AuthContext } from "../../App";

const AdminEfficacy = () => {
    const [efficacy, setEfficacy] = useState<string>('');
    const [efficacies, setEfficacies] = useState<Array<Efficacy>>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [clickId, setClickId] = useState<number>(0);
    const {alert, setAlert} = useContext(AuthContext);

    const showEfficacyEdit = (id: number) => {
        setIsEdit(true);
        setClickId(id);
    }

    const handleUpdateEfficacy = async (id: number) => {
        try {
          const params = setParams(id);
          const efficacyUpdateRes = await updateEfficacy(params);
          if (efficacyUpdateRes.status === 200) {
            setIsEdit(false);
            setEfficacy('');
            setAlert({ ...alert, open: true, severity: 'success', message: '効能の更新に成功しました。' });
          } else {
            setAlert({ ...alert, open: true, severity: 'error', message: '効能の更新に失敗しました。' });
          }
        } catch(e) {
          setAlert({ ...alert, open: true, severity: 'error', message: '効能の更新に失敗しました。' });
        }
    }

    const getEfficacyLists = async() => {
      try {
        const res = await getEfficacyList();
        setEfficacies(res);
      } catch(e) {
        // TODO エラーハンドリングを発生させる
        console.log(e);
      }
    }

    const setParams = (id: number) => {
      return {
        id: id,
        name: efficacy
      }
    }

    useEffect(() => {
        getEfficacyLists();
    }, []);

    return(
      <AdminLayout>
        <AlertMessage
          open={alert?.open ?? false}
          severity={alert?.severity ?? ''}
          message={alert?.message ?? ''}
        />
        <div className="text-right">
          <a href="/admin/efficacies/create" className="admin__create_btn">新規作成</a>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" className="px-6 py-3">
                          ID
                      </th>
                      <th scope="col" className="px-6 py-3">
                          効能名
                      </th>
                      <th scope="col" className="px-6 py-3">
                        <span className="sr-only">
                          Edit
                        </span>
                      </th>
                  </tr>
              </thead>
              <tbody>
              {efficacies?.map((efficacie) => (
                <>
                  {isEdit && clickId === efficacie.id ? (
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {efficacie.id}
                      </th>
                      <td className="px-6 py-4">
                        <input defaultValue={efficacie.name} onChange={(e) => setEfficacy(e.target.value)} />
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex">
                          <button
                            type="submit"
                            onClick={() => handleUpdateEfficacy(efficacie.id)}
                            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          >
                            <p>
                              更新する
                            </p>
                          </button>
                          <button
                            type="submit"
                            onClick={() => setIsEdit(false)}
                            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          >キャンセル</button>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {efficacie.id}
                      </th>
                      <td className="px-6 py-4">
                        {efficacie.name}
                      </td>
                      <td className="px-6 py-4">
                        <button
                          type="submit"
                          onClick={() => showEfficacyEdit(efficacie.id)}
                          className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                        >
                          <p>
                            編集
                          </p>
                        </button>
                      </td>
                    </tr>
                  )}
                  
                </>
              ))}
              </tbody>
          </table>
        </div>
      </AdminLayout>
    );
}

export default AdminEfficacy;