import Cookies from "js-cookie"
import client from "./client"
import { ResponseWeightInfos, WeightInfoParams } from "../interfaces/weight_info";
import WeightInfo from "../entities/weight_info";

export const getWeightInfo = (id: number) => {
  return client.get(`/weight_info/${id}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });
}

export const getWeightInfosByUserID = async (userID: number): Promise<Array<WeightInfo>> => {
  const weightInfos: Array<WeightInfo> = [];
  return client.get<ResponseWeightInfos>(`/weight_info/by_user?user_id=${userID}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  }).then((res) => {
    res.data.data.forEach((r) => {
      weightInfos.push({
        id: r.id,
        userId: r.userId,
        figures: r.figures,
        date: r.date,
        createdAt: r.createdAt,
        updatedAt: r.updatedAt,
      });
    });

    return weightInfos;
  });
}

export const createWeightInfo = (params: WeightInfoParams) => {
  return client.post('/weight_info', params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });
}

export const updateWeightInfo = (params: WeightInfoParams, id: number) => {
  return client.put(`/weight_info/${id}`, params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  })
}
