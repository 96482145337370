import Cookies from 'js-cookie';
import { ResponseSleepInfos, SleepInfoParams } from '../interfaces/sleep_info';
import SleepInfo from '../entities/sleep_info';
import client from './client';

export const getSleepInfo = (id: number) => {
  return client.get(`/sleep_info/${id}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });
}

export const getSleepInfosByUserID = async (userID: number): Promise<Array<SleepInfo>> => {
  const sleepInfos: Array<SleepInfo> = [];
  return client.get<ResponseSleepInfos>(`/sleep_info/by_user?user_id=${userID}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  }).then((res) => {
    res.data.data.forEach((r) => {
      sleepInfos.push({
        id: r.id,
        userId: r.userId,
        fallingAsleepTime: r.fallingAsleepTime,
        gettingUpTime: r.gettingUpTime,
        date: r.date,
        createdAt: r.createdAt,
        updatedAt: r.updatedAt,
      });
    });

    return sleepInfos;
  });
}

export const createSleepInfo = (params: SleepInfoParams) => {
  return client.post('/sleep_info', params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  })
}

export const updateSleepInfo = (params: SleepInfoParams, id: number) => {
  return client.put(`/sleep_info/${id}`, params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  })
}
