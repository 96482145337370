import Cookies from "js-cookie"
import client from "./client"
import { RequestCompleteParams } from "../interfaces/complete";

export const getServiceWorkoutDetail = async(id: number) => {
  const res = await client.get(`/workouts/${id}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });

  return res;
}

export const getServiceWorkoutMovie = async(id: number, workoutId: number) => {
  const workoutMovieRes = await client.get(`/workouts/${id}/workout_movies/${workoutId}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });

  return workoutMovieRes;
}

export const createServiceCompleteWorkoutMovie = async(params: RequestCompleteParams) => {
  const completeRes = await client.post('/completes', params, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  })

  return completeRes;
}

export const getServiceWorkoutMovieLastId = async(id: number) => {
  const workoutMovieLengthRes = await client.get(`/workouts/${id}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });

  const length = workoutMovieLengthRes.data.workoutMovies.length;

  return workoutMovieLengthRes.data.workoutMovies[length - 1].id;
}