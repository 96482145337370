import React, { useEffect, useState } from "react"
import { AlertMessageProps } from '../../interfaces/alert'
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import styled from "styled-components";


const SAlert = styled.div`
  background-color: ${props => props.color};
  width: 100%;
  border-radius: 5px;
  margin: 0;
`;

// アラートメッセージ（何かアクションを行なった際の案内用に使い回す）
const AlertMessage = ({ severity, message, open }: AlertMessageProps) => {
  const colorSwitch = (severity: string) => {
    switch(severity) {
      case "error":
        return "#FDE8E8";
      case "success":
        return "#DEF7EC";
      default:
        return "";
    }
  }

  return (
    <>
      {open && (
        <SAlert color={colorSwitch(severity)}>
          <Alert
            color={severity}
            icon={HiInformationCircle}
          >
            <span>
              <p>
                { message }
              </p>
            </span>
          </Alert>
        </SAlert>
      )}
    </>
  )
}

export default AlertMessage;
