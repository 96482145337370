import Cookies from "js-cookie"
import client from "./client"
import { RequestCompleteParams } from "../interfaces/complete"

export const getCompleteToToday = (params: RequestCompleteParams, date?: string) => {
  const url = date ? `/completes/${params.kindId}?date=${date}` : `/completes/${params.kindId}`;
  return client.get(url, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      'client': Cookies.get('_client'),
      'uid': Cookies.get('_uid')
    }
  })
}