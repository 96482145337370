import { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import { User } from "../interfaces/auth";
import { getCurrentUser, logout } from "../services/auth";

import accountActive from "../assets/images/account-active.svg";
import todoInActive from "../assets/images/todo-inactive.svg";
import dataInActive from "../assets/images/data-inactive.svg";
import { AuthContext } from "../App";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../components/ Loading";

const AccountSetting = () => {

  const [currentUser, setCurrentUser] = useState<User>();
  const [expiredDate, setExpiredDate] = useState<Date>(new Date());
  const navigate = useNavigate();
  const { alert, setAlert } = useContext(AuthContext);
  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();

  const fetchMe = async () => {
    dispatch(startLoading());
    try {
      const res = await getCurrentUser();
      setCurrentUser(res?.data.data);

      // 契約満了日の設定
      const expiredDate = new Date(res?.data.data.createdAt ?? new Date());
      expiredDate.setMonth(expiredDate.getMonth() + 3)
      setExpiredDate(expiredDate);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  const replaceDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}/${month}/${day}`;
  }

  const handleLogout = async () => {
    try {
      await logout();
      setAlert({ ...alert, open: true, severity: 'success', message: 'ログアウトに成功しました' });
      window.location.reload()
      // navigate('/sign_in');
    } catch (e) {
      console.log(e);
    }
  }

  function withdrawal() {
    const confirm = window.confirm(`退会する際は運営にご連絡お願いします。\n問題なければ、退会処理に進みます。\n本当によろしいですか？`);
    if (!confirm) return;
    window.location.href = 'mailto:info@body-science.co.jp';
  }

  useEffect(() => {
    fetchMe();
  }, [setCurrentUser]);

  return (
    <div>
      {isLoading ? <LoadingComponent /> : (
        <div className="wrapper">
          <Header />
          <nav className="foot-common-menu">
            <ul className="flex a-center">
              <li>
                <a href="/todo">
                  {/* <figure className="inactive-icon"><img src={todoActive} /></figure> */}
                  <figure className="inactive-icon"><img src={todoInActive} /></figure>
                  <span>デイリーToDo</span>
                </a>
              </li>
              <li>
                <a href="/data">
                  <figure className="inactive-icon"><img src={dataInActive} /></figure>
                  <span>データ</span>
                </a>
              </li>
              <li className="active">
                <a href="/">
                  <figure className="active-icon"><img src={accountActive} /></figure>
                  <span>アカウント</span>
                </a>
              </li>
            </ul>
          </nav>
          <main className="page-account-setting">
            <section className="account-setting-contents">
              <div className="l-inner">
                <div className="title t-center">アカウント設定</div>
                <div className="col2 border shadow content">
                  <div className="data-container">
                    <ul>
                      <li className="item">
                        <div className="item-label">メールアドレス</div>
                        <div className="item-value">{currentUser?.email}</div>
                        <div className="line" />
                      </li>
                      <li className="item">
                        <div className="item-label">氏名</div>
                        <div className="item-value">{currentUser?.name}</div>
                        <div className="line" />
                      </li>
                      <li className="item">
                        <div className="item-label">会員登録日</div>
                        <div className="item-value">{replaceDate(new Date(currentUser?.createdAt ?? new Date()))}</div>
                        <div className="line" />
                      </li>
                      <li className="item">
                        <div className="item-label">契約終了日</div>
                        <div className="item-value">{replaceDate(expiredDate)}</div>
                        <div className="line" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btn-area">
                  <button type="submit" onClick={handleLogout} className="btn">ログアウト</button>
                  <button type="submit" onClick={withdrawal} className="btn">退会</button>
                </div>
              </div>

            </section>
          </main>
        </div >
      )}
    </div>
  );
}

export default AccountSetting;