import Logo from '../assets/images/logo.svg';

const Header = () => {
  return(
    <header>
      <div className="l-inner flex f-center a-center">
        <figure className="logo">
          <img src={Logo} alt="BODY SCIENCE ONLINE" />
        </figure>
      </div>
    </header>
  );
}

export default Header;
