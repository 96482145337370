import Cookies from "js-cookie"
import client from "./client"

export const getTodo = (date?: string) => {
  const url = date ? `/todo?date=${date}` : '/todo';
  const todo = client.get(url, { headers: {
    'access-token': Cookies.get('_access_token'),
    'client': Cookies.get('_client'),
    'uid': Cookies.get('_uid')
  } });

  return todo;
}

export const getTodoLists = (user_id: number) => {
  const url = `/todo/lists/${user_id}`;
  const getLists = client.get(url, { headers: {
    'access-token': Cookies.get('_access_token'),
    'client': Cookies.get('_client'),
    'uid': Cookies.get('_uid')
  } });

  return getLists;
}

export const createTodo = async (userId: number) => {
  const url = '/todo';
  await client.post(url, {userId: userId}, { headers: {
    'access-token': Cookies.get('_access_token'),
    'client': Cookies.get('_client'),
    'uid': Cookies.get('_uid')
  } });
}
