export function getWeeklyLabel(values: Array<number>) {
  const weeklyLabel = [];

  for (let i = 0; i < values.length; i++) {
    if (i === 0) {
      weeklyLabel.push("今週");
    } else if (i === 1) {
      weeklyLabel.push("先週");
    } else if (i === 2) {
      weeklyLabel.push("先々週");
    } else {
      weeklyLabel.push(`${i + 2}週間前`);
    }
  }
  weeklyLabel.reverse();
  return weeklyLabel;
}


