import { useCallback, FC, Dispatch, SetStateAction, useEffect, useContext } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; 
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { format } from "date-fns";
import styled from "styled-components";

import { AuthContext } from "../App";
import { TodoInterface } from '../interfaces/todo';

const StyleWrapper = styled.div`
  .fc .fc-daygrid {
    height: 400px;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }
  .fc .fc-toolbar-title {
    font-size: 1rem;
    color: #37362f;
  }
  .fc .fc-button-primary {
    font-size: 0.75rem;
    background-color: #ffffff00;
    color: #acaba9;
    border: none;
    outline: none;
  }
  .fc-toolbar-chunk {
    display: flex;
  }
  .fc .fc-today-button {
    background-color: #ffffff00;
    color: #37362f;
    border: none;
    outline: none;
  }
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #ffffff00;
    color: #acaba9;
    box-shadow: none;
  }
  .fc .fc-button-primary:not(:disabled):focus,
  .fc .fc-button-primary:not(:disabled).fc-button-focus {
    background-color: #ffffff00;
    color: #acaba9;
    box-shadow: none;
  }
  .fc .fc-today-button:disabled {
    opacity: 1;
  }
  .fc .fc-col-header-cell {
    font-size: 0.75rem;
    font-weight: normal;
    color: #b6b5b3;
    border: none;
  }
  .fc .fc-scrollgrid {
    border-width: 0;
  }

  .fc .fc-scrollgrid-section > * {
    border: none;
  }

  .fc .fc-scrollgrid-sync-table {
    border: 1px;
  }
`
type EventInput = {
  title: string,
  start: string
}

const Calendar: FC<{ getTodo: (date?: string) => Promise<void>, setDay: (date: Date) => void, todoLists: TodoInterface[], startDate: string, getCurrentDay: (date: Date) => void }> = ({ getTodo, setDay, todoLists, startDate, getCurrentDay }) => {
  const { currentUser, currentTodo } = useContext(AuthContext);
  const renderDayCell = (e: any) => {
    const { date, dayNumberText } = e
    const renderDayNumberText =
      dayNumberText === '1日'
        ? format(date, 'M月d日')
        : dayNumberText.replace('日', '')
    return renderDayNumberText
  }

  const events: EventInput[] = todoLists.map(todo => {
    return (
      {
        title: todo.isComplete ? '完了' : '未完了',
        start: format(new Date(todo.date), 'yyyy-MM-dd')
      }
    )
  });

  const handleDateClick = useCallback((arg: DateClickArg) => {
    const today = new Date();
    const formatStartDate = new Date(startDate).setHours(0, 0, 0, 0)

    if (today < arg.date) {
      alert('未来の予定は選択できません。');
    } else if (formatStartDate > arg.date.setHours(0, 0, 0, 0)) {
      alert('登録開始日以前の日付なので選択できません。');
    } else {
      getTodo(arg.dateStr);
      setDay(new Date(arg.dateStr));
      getCurrentDay(new Date(arg.dateStr));
    }
  }, []);

  useEffect(() => {
  })
  return(
    <StyleWrapper>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale="ja"
        dateClick={handleDateClick}
        headerToolbar={{ left: 'title', center: '', right: 'prev today next' }}
        aspectRatio={1.6}
        businessHours={{ daysOfWeek: [1, 2, 3, 4, 5] }}
        events={events}
        eventBackgroundColor={'#FFFFFF'}
        eventBorderColor={'#acaba9'}
        eventTextColor={'#37362f'}
      />
    </StyleWrapper>
  );
}

export default Calendar;
