
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AuthContext } from "../App";
import { formatDate } from "../utils";
import { createPostMeal, updatePostMeal } from "../services/training_recipe";
import { PostMeal, RequestPostMealParams, RequestUpdateMealParams } from "../interfaces/training_recipe";

import arrow from "../assets/images/arrow-back.svg"
import check from "../assets/images/check.svg"
import todoEating from "../assets/images/kv-todo-eating.jpg";
import AlertMessage from "../components/utils/AlertMessage";
import { AlertMessageProps } from "../interfaces/alert";
import { getPostMeal } from "../services/training_recipe";
import { AppDispatch, RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../components/ Loading";

const Eating = () => {
  const [today, setToday] = useState<string>('');
  const [alert, setAlert] = useState<AlertMessageProps>();
  // getで取得した食事情報
  const [postMeal, setPostMeal] = useState<PostMeal>();
  // 画像データ
  const [breakfastImage, setBreakfastImage] = useState<File | null>(null);
  const [lunchImage, setLunchImage] = useState<File | null>(null);
  const [dinnerImage, setDinnerImage] = useState<File | null>(null);
  // プレビューURL
  const [previewBreakfast, setPreviewBreakfast] = useState<string>('');
  const [previewLunch, setPreviewLunch] = useState<string>('');
  const [previewDinner, setPreviewDinner] = useState<string>('');
  // 画像データにエラーが起きているかどうか
  const [isBreakfastImageError, setIsBreakfastImageError] = useState<boolean>(false);
  const [isLunchImageError, setIsLunchImageError] = useState<boolean>(false);
  const [isDinnerImageError, setIsDinnerImageError] = useState<boolean>(false);
  const [breakfastImageErrorMessage, setBreakfastImageErrorMessage] = useState<string>('');
  const [lunchImageErrorMessage, setLunchImageErrorMessage] = useState<string>('');
  const [dinnerImageErrorMessage, setDinnerImageErrorMessage] = useState<string>('');
  // 共通エラーが起きているかどうか
  const [isCommonError, setIsCommonError] = useState<boolean>(false);
  const [commonErrorMessage, setCommonErrorMessage] = useState<string>('');

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { currentUser, currentTodo } = useContext(AuthContext);
  const params = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const queryParameter = searchParams.get('date');
  const maxSize = 10485760; // 10MB bytes

  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();

  // ページ表示時に食事情報を取得し、画像データをセットする
  const getPostMealImages = async () => {
    if (currentTodo?.postMealId) {
      dispatch(startLoading());
      try {
        const res = await getPostMeal(currentTodo?.postMealId ?? 0);
        setPostMeal(res.data);
        if (res.data?.breakfastImageUrl) {
          setBreakfastImage(res.data?.breakfastImageUrl);
          setPreviewBreakfast(res.data?.breakfastImageUrl);
        }
        if (res.data?.lunchImageUrl) {
          setLunchImage(res.data?.lunchImageUrl);
          setPreviewLunch(res.data?.lunchImageUrl);
        }
        if (res.data?.dinnerImageUrl) {
          setDinnerImage(res.data?.dinnerImageUrl);
          setPreviewDinner(res.data?.dinnerImageUrl);
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(stopLoading());
      }
    }
  }

  // 朝食画像データが変更されたら、画像データをセットする
  const onBreakfastImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const fileObject = e.target.files[0];

    if (fileObject.size > maxSize) {
      setAlert({ ...alert, open: true, severity: 'error', message: '10MB以下のファイルを選択してください' });
    } else if (!fileObject.type.startsWith('image/') && String(!fileObject.type) === 'application/pdf') {
      setAlert({ ...alert, open: true, severity: 'error', message: '画像ファイルを選択してください' });
    } else {
      const newFile = {
        ...fileObject,
        preview: URL.createObjectURL(fileObject),
      };
      setPreviewBreakfast(newFile.preview);
      setBreakfastImage(fileObject);
    }
  }

  // 昼食画像データが変更されたら、画像データをセットする
  const onLunchImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const fileObject = e.target.files[0];

    if (fileObject.size > maxSize) {
      setAlert({ ...alert, open: true, severity: 'error', message: '10MB以下のファイルを選択してください' });
    } else if (!fileObject.type.startsWith('image/') && String(!fileObject.type) === 'application/pdf') {
      setAlert({ ...alert, open: true, severity: 'error', message: '画像ファイルを選択してください' });
    } else {
      const newFile = {
        ...fileObject,
        preview: URL.createObjectURL(fileObject),
      };
      setPreviewLunch(newFile.preview);
      setLunchImage(fileObject);
    }
  }

  // 夕食画像データが変更されたら、画像データをセットする
  const onDinnerImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const fileObject = e.target.files[0];

    if (fileObject.size > maxSize) {
      setAlert({ ...alert, open: true, severity: 'error', message: '10MB以下のファイルを選択してください' });
    } else if (!fileObject.type.startsWith('image/') && String(!fileObject.type) === 'application/pdf') {
      setAlert({ ...alert, open: true, severity: 'error', message: '画像ファイルを選択してください' });
    } else {
      const newFile = {
        ...fileObject,
        preview: URL.createObjectURL(fileObject),
      };
      setPreviewDinner(newFile.preview);
      setDinnerImage(fileObject);
    }
  }

  // 画像データを送信する
  const handleFileSubmit = async (e: any) => {
    dispatch(startLoading());
    e.preventDefault();

    // 画像データが基準を満たしているかチェックする
    const checkedBreakfastImage = breakfastImage ? checkImageSize(breakfastImage ?? undefined, 'breakfast') : null;
    const checkedLunchImage = lunchImage ? checkImageSize(lunchImage ?? undefined, 'lunch') : null;
    const checkedDinnerImage = dinnerImage ? checkImageSize(dinnerImage!, 'dinner') : null;

    const formData = new FormData();

    // 存在する画像データをフォームデータにセットする
    if (checkedBreakfastImage) {
      formData.append('postMeal[:breakfast_image]', checkedBreakfastImage);
    }
    if (checkedLunchImage) {
      formData.append('postMeal[:lunch_image]', checkedLunchImage);
    }
    if (checkedDinnerImage) {
      formData.append('postMeal[:dinner_image]', checkedDinnerImage);
    }

    // 新規登録時のパラメータ
    const eatingPostParams: RequestPostMealParams = {
      userId: currentUser?.id ?? 0,
      breakfastImage: formData.get('postMeal[:breakfast_image]') ?? undefined,
      lunchImage: formData.get('postMeal[:lunch_image]') ?? undefined,
      dinnerImage: formData.get('postMeal[:dinner_image]')!,
      date: queryParameter ? queryParameter : '',
    }

    // 更新時のパラメータ
    const eatingUpdateParams: RequestUpdateMealParams = {
      id: postMeal?.id ?? 0,
      userId: currentUser?.id ?? 0,
      breakfastImage: formData.get('postMeal[:breakfast_image]') ?? undefined,
      lunchImage: formData.get('postMeal[:lunch_image]') ?? undefined,
      dinnerImage: formData.get('postMeal[:dinner_image]') ?? undefined,
      date: queryParameter ? queryParameter : '',
    }

    // 夕食の画像がないかつ、更新時に夕食の画像がない場合はエラーを返す
    if (!eatingPostParams.dinnerImage && !postMeal?.dinnerImageUrl) {
      setAlert({ ...alert, open: true, severity: 'error', message: `夕食の写真は必須です。` });
      return;
    }

    try {
      // 事前に食事情報を取得している場合は更新、そうでない場合は新規登録を行う
      const res = postMeal?.id ? await updatePostMeal(eatingUpdateParams, postMeal.id) : await createPostMeal(eatingPostParams);

      if (res.status === 200) {
        setIsSuccess(true);
        setAlert({ ...alert, open: true, severity: 'success', message: '食事情報の登録に成功しました。' });
      } else {
        dinnerImage ? setIsDinnerImageError(true) : setIsCommonError(true);
        dinnerImage ? setDinnerImageErrorMessage('画像を選択してください。') : setCommonErrorMessage('食事情報の登録に失敗しました。');
        setIsSuccess(false);
        setAlert({ ...alert, open: true, severity: 'error', message: '食事情報の登録に失敗しました。' });
      }
    } catch (e) {
      console.log(e);
      dinnerImage ? setIsDinnerImageError(true) : setIsCommonError(true);
      dinnerImage ? setDinnerImageErrorMessage('画像を選択してください。') : setCommonErrorMessage('食事情報の登録に失敗しました。');
      setIsSuccess(false);
      setAlert({ ...alert, open: true, severity: 'error', message: `食事情報の登録に失敗しました。${e}` });
    } finally {
      dispatch(stopLoading());
      setBreakfastImage(null);
      setLunchImage(null);
      setDinnerImage(null);
    }
  }

  // 画像データが基準を満たしているかチェックするメソッド
  const checkImageSize = (image?: File, type?: string) => {
    if (image && image?.size && image?.size <= maxSize && (image?.type.startsWith('image/') || image?.type === 'application/pdf')) {
      return image;
    } else {
      if (image) {
        // どの画像データかによって、それぞれのエラーメッセージを設定する
        switch (type) {
          case 'breakfast':
            if (postMeal?.breakfastImageUrl) {
              break;
            }
            setIsBreakfastImageError(true);
            setBreakfastImageErrorMessage('10MB以下のファイルを選択してください')
            break;
          case 'lunch':
            if (postMeal?.lunchImageUrl) {
              break;
            }
            setIsLunchImageError(true);
            setLunchImageErrorMessage('10MB以下のファイルを選択してください')
            break;
          case 'dinner':
            if (postMeal?.dinnerImageUrl) {
              break;
            }
            setIsDinnerImageError(true);
            setDinnerImageErrorMessage('10MB以下のファイルを選択してください')
            break;
          default:
        }
      } else {
        setIsCommonError(true);
        setCommonErrorMessage('画像を選択してください。');
      }
      image ? setAlert({ ...alert, open: true, severity: 'error', message: '10MB以下のファイルを選択してください' }) : setAlert({ ...alert, open: true, severity: 'error', message: '画像を選択してください。' });
    }
  }

  useEffect(() => {
    getPostMealImages();
    setToday(formatDate(new Date()));
  }, []);
  return (
    <div>
      {isLoading ? <LoadingComponent /> : (
        <div className="wrapper">
          <AlertMessage
            open={alert?.open ?? false}
            severity={alert?.severity ?? ''}
            message={alert?.message ?? ''}
          />
          <main className="page-todo-level01 page-todo-input">
            <article>
              <section className={isSuccess || currentTodo?.postMealId ? "page-heading shadow todo-completed" : "page-heading shadow"}>
                <div className="l-inner head-navi flex f-space a-center">
                  <a href={queryParameter ? "/recipe/" + params.id + `?date=${queryParameter}` : "/recipe/" + params.id} className="btn-back">
                    <figure><img src={arrow} /></figure>
                  </a>
                  <div className="checking">
                    <figure><img src={check} /></figure>
                  </div>
                </div>
                <div className="m-inner">
                  <div className="sub-info">
                    <p className="cat">{queryParameter ? queryParameter.replaceAll('-', '.') : today}</p>
                    <h2>食事情報を入力</h2>
                  </div>
                </div>
                <div className="bg bg-cover" style={{ backgroundImage: `url(${todoEating})` }}></div>
              </section>

              <section className="contents">
                <div className="l-inner">
                  <div className="overview border shadow">
                    <div className="text">
                      <div className="m-inner">
                        <p>本日の食事内容を入力して、レシピToDoを完了させましょう！入力する食事情報はレシピ通りの食事でなくも問題ございません。</p>
                      </div>
                    </div>
                    <div className="input-content">
                      <div className="m-inner">
                        <form onSubmit={handleFileSubmit}>
                          <fieldset>
                            <label>朝食の写真 *任意</label>
                            <label className="file-upload border-s">
                              <input type="file" name="朝食" accept="image/*" onChange={onBreakfastImageInputChange} className="border-s" />写真をアップロード
                            </label>
                            {isBreakfastImageError && (<p style={{ color: "red" }}>{breakfastImageErrorMessage}</p>)}
                            {previewBreakfast && <img src={previewBreakfast} alt="Description" />}
                          </fieldset>
                          <fieldset>
                            <label>昼食の写真 *任意</label>
                            <label className="file-upload border-s">
                              <input type="file" name="昼食" accept="image/*" onChange={onLunchImageInputChange} className="border-s" />写真をアップロード
                            </label>
                            {isLunchImageError && (<p style={{ color: "red" }}>{lunchImageErrorMessage}</p>)}
                            {previewLunch && <img src={previewLunch} alt="Description" />}
                          </fieldset>
                          <fieldset>
                            <label>夕食の写真 <span>*必須</span></label>
                            <label className="file-upload border-s">
                              <input type="file" name="晩御飯" accept="image/*" onChange={onDinnerImageInputChange} className="border-s" />写真をアップロード
                            </label>
                            {isDinnerImageError && (<p style={{ color: "red" }}>{dinnerImageErrorMessage}</p>)}
                            {previewDinner && <img src={previewDinner} alt="Description" />}
                          </fieldset>
                          <fieldset>
                            <div className="submit-box">
                              <button type="submit" className="bg-gradient border-s shadow-blue">送信する</button>
                              {isCommonError && (<p style={{ color: "red" }}>{commonErrorMessage}</p>)}
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </main >
        </div >
      )}
    </div>
  );
}

export default Eating;
