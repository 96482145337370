import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Headers, PasswordConfirmationParams } from "../interfaces/auth";
import { updatePassword } from "../services/auth";

const UserPasswordReset = () => {
  const [ password, setPassword ] = useState('');
  const [ header, setHeader ] = useState<Headers>();
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const handleUpdatePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params: PasswordConfirmationParams = {
      reset_password_token: query.get('token') ?? '',
      password: password,
      password_confirmation: password,
    }

    try {
      const res = await updatePassword(params, header?.uid ?? '', header?.client ?? '', header?.accessToken ?? '');
      if (res.status === 200) {
        navigate("/sign_in");
      }
    } catch(e) {
      console.log(e);
    }
  }

  const setHeaders = () => {
    setHeader({
      uid: query.get('uid') ?? '',
      client: query.get('client') ?? '',
      accessToken: query.get('access-token') ?? '',
    })
  }

  useEffect(() => {
    setHeaders();
  }, []);
  return(
    <div className="wrapper">
      <main className="page-sign">
        <form onSubmit={handleUpdatePasswordSubmit}>
          <article>
              <section className="page-heading">
                  <div className="m-inner">
                      <div className="page-title">
                          <span className="gradient">PASSWORD RESET</span>
                          <h1>新しいパスワードの設定</h1>
                      </div>
                  </div>
              </section>
              <section className="page-contents">
                  <div className="m-inner">
                      <div className="form-box">
                          <fieldset>
                              <label>パスワード</label>
                              <input type="password" name="password" value={password} onChange={((e) => setPassword(e.target.value))} />
                              <p className="caption">パスワードは大文字のアルファベットを含む英数字を用いて8~16文字で設定してください。</p>
                          </fieldset>
                          <fieldset className="submit">
                              <button type="submit" disabled={!password ? true : false}>パスワードを設定する</button>
                          </fieldset>
                      </div>
                  </div>
              </section>
          </article>
        </form>
      </main>
    </div>
  )
}

export default UserPasswordReset;
