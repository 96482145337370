import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AuthContext } from "../App";
import { getServiceTrainingRecipe } from "../services/training_recipe";
import { ResponseTrainingRecipe } from "../interfaces/training_recipe";

import arrow from "../assets/images/arrow-white.svg";
import arrowBlk from "../assets/images/arrow-back-blk.svg";
import check from "../assets/images/check.svg";
import tag from "../assets/images/tag.svg";
import topImage from "../assets/images/kv-recipe.jpg";
import { AppDispatch, RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../components/ Loading";

const Recipe = () => {
  const [recipe, setRecipe] = useState<ResponseTrainingRecipe>();
  const params = useParams();
  const { currentTodo } = useContext(AuthContext);
  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const queryParameter = searchParams.get('date');

  const getTrainingRecipe = async () => {
    dispatch(startLoading());
    try {
      const res = await getServiceTrainingRecipe(Number(params.id));
      setRecipe(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  useEffect(() => {
    getTrainingRecipe();
  }, []);

  return (
    <div>
      {isLoading ? <LoadingComponent /> : (
        <div className="wrapper">
          <a href={queryParameter ? "/recipe/" + params.id + "/eating" + `?date=${queryParameter}` : "/recipe/" + params.id + "/eating"} className="foot-next-btn">
            <span>今日の食事情報を入力</span>
            <figure><img src={arrow} style={{ transform: "rotate(-90deg)" }} /></figure>
          </a>
          <main className="page-todo-level02 page-workout-detail">
            <article>
              <section className={currentTodo?.postMealId ? "page-heading todo-completed" : "page-heading"}>
                <div className="l-inner head-navi flex f-space a-center">
                  <a href={queryParameter ? `/todo?date=${queryParameter}` : "/todo"} className="btn-back">
                    <figure><img src={arrowBlk} /></figure>
                  </a>
                  <div className="checking">
                    <figure><img src={check} /></figure>
                  </div>
                </div>
                <img src={topImage} className="video" />
                <div className="page-title">
                  <div className="l-inner">
                    <h2>{recipe?.title}</h2>
                    <div className="sub-info flex a-center">
                      <p className="cat">トレーニングレシピ</p>
                    </div>
                    <ul className="tags flex f-start f-wrap">
                      {recipe?.efficacies.map((efficacy) => (
                        <li key={efficacy.id} className="flex f-space a-center border-s">
                          <figure><img src={tag} /></figure>
                          <p>{efficacy.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>

              <section className="contents">
                <div className="l-inner">
                  <div className="cl-content">
                    <h2>材料</h2>
                    <ul className="dot-list">
                      {recipe?.material.split(/(\n)/).map((material, index) => {
                        return (
                          <React.Fragment key={index}>
                            {material.match(/\n/) ? <br /> : material && <li><p>{material}</p></li>}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="cl-content">
                    <h2>調理方法</h2>
                    <ol className="num-list">
                      {recipe?.cookingMethod.split(/(\n)/).map((method, index) => {
                        return (
                          <React.Fragment key={index}>
                            {method.match(/\n/) ? <br /> : method && <li><p>{method}</p></li>}
                          </React.Fragment>
                        );
                      })}
                    </ol>
                  </div>
                </div>
              </section>
            </article>
          </main>
        </div>
      )}
    </div>
  );
}

export default Recipe;
