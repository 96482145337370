import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { dayOfWeek } from "../constants//index";
import client from '../services/client';
import { TodoInterface } from '../interfaces/todo';
import { AuthContext } from "../App";
import Header from '../components/Header';

// 画像のインポート
import todoActive from "../assets/images/todo-active.svg";
import dataInActive from "../assets/images/data-inactive.svg";
import activeInActive from "../assets/images/account-inactive.svg";
import arrowWhite from "../assets/images/arrow-white.svg";
import theme from "../assets/images/theme.svg";
import check from "../assets/images/check.svg";
import arrow from "../assets/images/arrow.svg";
import play from "../assets/images/play.svg";
import todoWeight from "../assets/images/kv-todo-wight.jpg";
import todoSleep from "../assets/images/kv-todo-sleep.jpg";
import recipeKv from "../assets/images/kv-recipe.jpg";
import Cookies from "js-cookie";
import Calendar from "../components/Calendar";
import AlertMessage from "../components/utils/AlertMessage";
import { getTotalTime } from "../utils";
import { getTodoLists } from "../services/todo";
import { getServiceWorkoutDetail } from "../services/workouts";
import { WorkoutInterface } from "../interfaces/workout";
import { AppDispatch, RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../components/ Loading";

const Todo: React.FC = () => {
  const [active, setActive] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [todo, setTodo] = useState<TodoInterface>();
  const [todoLists, setTodoLists] = useState<TodoInterface[]>([]);
  const [currentDay, setCurrentDay] = useState<number>(0);
  const [workout, setWorkout] = useState<WorkoutInterface>();
  const { currentUser, alert } = useContext(AuthContext);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const queryParameter = searchParams.get('date');
  const totalMinutes = workout?.workoutMovies ? getTotalTime(workout?.workoutMovies.map((movie) => { return movie.playTime }) ?? []) : 0;

  const formatDate = (d: Date) => {
    const year: number = d.getFullYear();
    const month: string = ("00" + (d.getMonth() + 1)).slice(-2);;
    const day: string = ("00" + d.getDate()).slice(-2);
    return `${year + "-" + month + "-" + day}`;
  }

  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();

  const getCurrentDay = (date?: Date) => {
    const today: Date = new Date(formatDate(new Date()));
    const createdAtDate = formatDate(new Date(currentUser?.createdAt ?? new Date));
    const userCreatedAt: Date = new Date(createdAtDate);
    const diffDay: number = Math.floor((today.getTime() - userCreatedAt.getTime()) / 86400000);
    if (date) {
      const variableDiffDay = diffDay - (Math.floor((today.getTime() - date.getTime()) / 86400000));
      setCurrentDay(variableDiffDay + 1);
    } else {
      setCurrentDay(diffDay + 1);
    }
  }

  const getTodo = async (date?: string): Promise<void> => {
    dispatch(startLoading());
    try {
      const url = date ? `/todo?date=${date}` : '/todo';
      const res = await client.get(url, {
        headers: {
          'access-token': Cookies.get('_access_token'),
          'client': Cookies.get('_client'),
          'uid': Cookies.get('_uid')
        }
      });
      setTodo(res.data);
      getWorkout(res.data.workoutId);
      if (res.status === 200 && date) {
        navigate(`/todo?date=${date}`);
        setActive(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  // TODOもっと簡潔に記載
  const getWorkout = async (id: number) => {
    try {
      const res = await getServiceWorkoutDetail(id);
      if (res.status === 200) {
        setWorkout(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const loadGetTodoLists = async () => {
    try {
      const todoListsRes = await getTodoLists(currentUser?.id ?? 0);
      if (todoListsRes.status === 200) {
        setTodoLists(todoListsRes.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onClickToggleActive = () => {
    setActive(!active);
  }

  const setDay = (d: Date) => {
    const year: number = d.getFullYear();
    const month: string = ("00" + (d.getMonth() + 1)).slice(-2);;
    const dayofweek = d.getDay();
    const day: string = ("00" + d.getDate()).slice(-2);
    setDate(`${year}.${month}.${day} ${dayOfWeek[dayofweek]}`);
  }

  useEffect(() => {
    if (queryParameter) {
      getTodo(queryParameter ?? '');
      setDay(new Date(queryParameter ?? ''));
      getCurrentDay(new Date(queryParameter ?? ''));
    } else {
      getTodo();
      setDay(new Date);
      getCurrentDay();
    }
  }, []);

  useLayoutEffect(() => {
    loadGetTodoLists();
  }, []);

  return (
    <div>
      {isLoading ? <LoadingComponent /> : (
        <div className="wrapper">
          <Header />
          <nav className="foot-common-menu">
            <ul className="flex a-center">
              <li className="active">
                <a href="/todo">
                  <figure className="active-icon"><img src={todoActive} /></figure>
                  <span>デイリーToDo</span>
                </a>
              </li>
              <li>
                <a href="/data">
                  <figure className="inactive-icon"><img src={dataInActive} /></figure>
                  <span>データ</span>
                </a>
              </li>
              <li>
                <a href="/">
                  <figure className="inactive-icon"><img src={activeInActive} /></figure>
                  <span>アカウント</span>
                </a>
              </li>
            </ul>
          </nav>
          <main className="page-todo">
            <AlertMessage
              open={alert?.open ?? false}
              severity={alert?.severity ?? ''}
              message={alert?.message ?? ''}
            />
            <article>
              <section className="page-heading shadow">
                <div className="l-inner">
                  <div className="date-box border">
                    <div className={active ? "title active" : "title"} onClick={onClickToggleActive}>
                      <h2>{date}</h2>
                      <figure><img src={arrowWhite} /></figure>
                    </div>
                    <div className={active ? "calendar-box active" : "calendar-box"}>
                      <div className="content">
                        <Calendar getTodo={getTodo} setDay={setDay} todoLists={todoLists} startDate={formatDate(new Date(currentUser?.createdAt ?? ''))} getCurrentDay={getCurrentDay} />
                      </div>
                    </div>
                  </div>
                  <div className="heading flex f-space a-center">
                    <h3>デイリーToDo</h3>
                    <div className="date-count flex f-start a-center">
                      <figure><img src={todoActive} /></figure>
                      <p className="gradient">開始から{currentDay}日目</p>
                    </div>
                  </div>
                  <div className="theme-box">
                    <div className="content border">
                      <div className="title flex a-center">
                        <figure><img src={theme} /></figure>
                        <h4 className="gradient">今日のテーマ</h4>
                      </div>
                      <p>{todo?.theme.content}</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="todo-contents">
                <div className="l-inner">
                  <ul className="flex f-space f-wrap">
                    <li className={todo?.weightInfoId ? "col2 border shadow todo-completed" : "col2 border shadow"}>
                      <a href={queryParameter ? `/weight_info?date=${queryParameter}` : "/weight_info"}>
                        <div className="kv bg" style={{ backgroundImage: `url(${todoWeight})` }}>
                          <div className="checking">
                            <figure><img src={check} /></figure>
                          </div>
                        </div>
                        <div className="title flex f-space a-center">
                          <h3>今日の体重を入力</h3>
                          <figure><img src={arrow} /></figure>
                        </div>
                      </a>
                    </li>
                    <li className={todo?.sleepInfoId ? "col2 border shadow todo-completed" : "col2 border shadow"}>
                      <a href={queryParameter ? `/sleep_info?date=${queryParameter}` : "/sleep_info"}>
                        <div className="kv bg" style={{ backgroundImage: `url(${todoSleep})` }}>
                          <div className="checking">
                            <figure><img src={check} /></figure>
                          </div>
                        </div>
                        <div className="title flex f-space a-center">
                          <h3>睡眠時間を入力</h3>
                          <figure><img src={arrow} /></figure>
                        </div>
                      </a>
                    </li>
                    <li className={todo?.workoutComplete ? "border shadow todo-completed" : "border shadow"}>
                      <a href={queryParameter ? '/workouts/' + todo?.workoutId + `?date=${queryParameter}` : '/workouts/' + todo?.workoutId}>
                        <div className="kv bg bg-cover" style={{ backgroundImage: `url(${todo?.workout.thumbnailUri})`, width: "100" }}>
                          <div className="checking">
                            <figure><img src={check} /></figure>
                          </div>
                          <div className="title flex f-space">
                            <div className="main">
                              <div className="sub-info flex a-center">
                                <p className="cat">トレーニング動画</p>
                                <span></span>
                                <p className="time">計{totalMinutes}分</p>
                              </div>
                              <h3>{todo?.workout.title}</h3>
                            </div>
                            <figure><img src={play} /></figure>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className={todo?.postMealId ? "border shadow todo-completed" : "border shadow"}>
                      <a href={queryParameter ? "/recipe/" + todo?.trainingRecipe.id + `?date=${queryParameter}` : "/recipe/" + todo?.trainingRecipe.id}>
                        <div className="kv bg" style={{ backgroundImage: `url(${recipeKv})`, backgroundSize: 'auto' }}>
                          <div className="checking">
                            <figure><img src={check} /></figure>
                          </div>
                          <div className="title flex f-space">
                            <div className="main">
                              <div className="sub-info flex a-center">
                                <p className="cat">トレーニングレシピ</p>
                              </div>
                              <h3>{todo?.trainingRecipe.title}</h3>
                            </div>
                            <figure><img src={arrow} /></figure>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            </article>
          </main>
        </div>
      )}
    </div>
  );
}

export default Todo;
