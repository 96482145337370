import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import client from '../services/client';
import { SignUpParams } from "../interfaces/auth";
import { signUp } from "../services/auth";
import Cookies from "js-cookie";
import { AuthContext } from "../App";
import { createTodo } from "../services/todo";

interface QueryParams {
  [key: string]: string;
}


const SignUp: React.FC = () => {
  const [ password, setPassword ] = useState<string>('');
  const [ email, setEmail ] = useState<string>('');
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false)
  const { setIsSignedIn, setCurrentUser } = useContext(AuthContext);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const name = query.get("name") ?? '';

  const decryptEmail = async() => {
    const email = await client.get(`/admin/pre_users/decrypt/${query.get("email")}`);
    setEmail(email.data);
  }

  const handleSignUpSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const params: SignUpParams = {
      email: email,
      password: password,
      name: name,
    }

    try {
      const res = await signUp(params);

      // サインアップ後にログイン状態にする
      if (res.status === 200) {
        Cookies.set("_access_token", res.headers["access-token"]);
        Cookies.set("_client", res.headers["client"]);
        Cookies.set("_uid", res.headers["uid"]);

        setIsSignedIn(true);
        setCurrentUser(res.data.data);
        await createTodo(res.data.data.id);

        // react routerのnavigate機能を使うとspaの場合データ読み込みがうまくいかないためlocationで遷移
        window.location.href = '/todo';
      } else {
        setAlertMessageOpen(true);
      }
    } catch (e) {
      console.log(e);
      setAlertMessageOpen(true);
    }
  }

  useEffect(() => {
    decryptEmail();
  },[]);
  return(
    <div className="wrapper">
      <main className="page-sign">
          {/* <script type="text/javascript">
              let vh = window.innerHeight * 0.01;
              document.documentElement.style.setProperty('--vh', '${vh}px');
          </script> */}
        <article>
          <section className="page-heading">
            <div className="m-inner">
              <div className="page-title">
                <span className="gradient">BODY SCIENCE ONLINEへようこそ!!</span>
                <h1>新規会員登録</h1>
                <p>パスワードを設定して、会員登録を完了させましょう💪</p>
              </div>
            </div>
          </section>

          <section className="page-contents">
            <div className="m-inner">
              <div className="form-box">
                <form>
                  <fieldset>
                    <label>メールアドレス</label>
                    <input type="email" name="email" value={email} className="readonly" readOnly/>
                  </fieldset>
                  <fieldset>
                    <label>パスワード</label>
                    <input type="password" name="password" value={password} onChange={((e) => setPassword(e.target.value))} required />
                    <p className="caption">パスワードは大文字のアルファベットを含む英数字を用いて8~16文字で設定してください。</p>
                  </fieldset>
                  <input type="hidden" name="name" value="test user" />
                  <fieldset className="submit">
                    <button type="submit" disabled={!email || !password ? true : false} onClick={handleSignUpSubmit}>会員登録する</button>
                  </fieldset>
                </form>
              </div>
            </div>
          </section>
        </article>
      </main>
    </div>
  );
}

export default SignUp;