import { Chart, registerables } from "chart.js"
import { Line } from "react-chartjs-2"

Chart.register(...registerables)

const Graph = (props: any) => {
  const labels = props.graphData.labels;
  const isWeight = props.graphData.isWeight ?? false;
  const isDate = props.graphData.isDate ?? false;
  const isFallingASleepTime = props.graphData.isFallingASleepTime ?? false;

  const convertTimeToFloat = (time: string) => {
    const hour = parseFloat(time);
    return hour >= 12 ? hour - 24 : hour;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: props.graphData.title,
        data: isWeight ? props.graphData.data : props.graphData.data.map((time: any) => convertTimeToFloat(time)),  // データを浮動小数点数に変換
        borderColor: "rgb(75, 192, 192)",
      },
    ],
  }

  const options: {} = isDate ? {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: false,
        min: isFallingASleepTime ? -6 : 3,
        max: isFallingASleepTime ? 6 : 15,
        ticks: {
          autoSkip: false,
          stepSize: 2,
          callback: function (value: any) {
            // この例では、y軸の値に応じて特定の文字列を返します
            switch (value) {
              case -12:
                return "12:00";
              case -11:
                return "13:00";
              case -10:
                return "14:00";
              case -9:
                return "15:00";
              case -8:
                return "16:00";
              case -7:
                return "17:00";
              case -6:
                return "18:00";
              case -5:
                return "19:00";
              case -4:
                return "20:00";
              case -3:
                return "21:00";
              case -2:
                return "22:00";
              case -1:
                return "23:00";
              case 0:
                return "24:00";
              case 1:
                return "01:00";
              case 2:
                return "02:00";
              case 3:
                return "03:00";
              case 4:
                return "04:00";
              case 5:
                return "05:00";
              case 6:
                return "06:00";
              case 7:
                return "07:00";
              case 8:
                return "08:00";
              case 9:
                return "09:00";
              case 10:
                return "10:00";
              case 11:
                return "11:00";
              case 12:
                return "12:00";
              case 13:
                return "13:00";
              case 14:
                return "14:00";
              case 15:
                return "15:00";
              default:
                return value; // その他の値はそのままの数字として表示
            }
          }
        }
      }
    }
  } : {
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className="data-graph">
      <Line height={300} width={800} data={data} options={options} />
    </div>

  )
}

export default Graph
