import { useContext, useState } from "react";
import AlertMessage from "../../components/utils/AlertMessage";
import { AlertMessageProps } from "../../interfaces/alert";
import { AuthContext } from "../../App";
import { Params } from "../../interfaces/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { signIn } from "../../services/auth";

const AdminSignIn = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [alert, setAlert] = useState<AlertMessageProps>();
  const { setIsSignedIn, setCurrentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const inputEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const inputPassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  const generateParams = () => {
    const signInParams: Params = {
      email: email,
      password: password
    }
    return signInParams;
  }

  const handleSignInSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const params: Params = generateParams();
    try {
      const res = await signIn(params);
      if (res.status === 200) {
        Cookies.set("_access_token", res.headers["access-token"]);
        Cookies.set("_client", res.headers["client"]);
        Cookies.set("_uid", res.headers["uid"]);

        setIsSignedIn(true);
        setCurrentUser(res.data.data);

        navigate("/admin/pre_users");
        console.log("Signed in successfully!")
      } else {
        setAlert({ ...alert, open: true, severity: 'error', message: 'メールアドレスまたはパスワードが無効です。' })
      }
    } catch (e) {
      console.log(e);
      setAlert({ ...alert, open: true, severity: 'error', message: 'メールアドレスまたはパスワードが無効です。' });
    }
  }

  return (
    <div className="wrapper">
      <AlertMessage
        open={alert?.open ?? false}
        severity={alert?.severity ?? ''}
        message={alert?.message ?? ''}
      />
      <main className="page-sign">
        <article>
          <section className="page-heading">
            <div className="m-inner">
              <div className="page-title">
                <span className="gradient">Admin LOGIN</span>
                <h1>管理画面用ログイン</h1>
              </div>
            </div>
          </section>

          <section className="page-contents">
            <div className="m-inner">
              <div className="form-box">
                <form >
                  <fieldset>
                    <label>メールアドレス</label>
                    <input type="email" name="email" pattern=".+@globex\.com" value={email} onChange={inputEmail} required />
                  </fieldset>
                  <fieldset>
                    <label>パスワード</label>
                    <input type="password" name="password" value={password} onChange={inputPassword} required />
                    <p className="caption">パスワードは大文字のアルファベットを含む英数字を用いて8~16文字で設定してください。</p>
                  </fieldset>
                  <fieldset className="submit">
                    <button type="submit" disabled={!email || !password ? true : false} onClick={handleSignInSubmit}>ログインする</button>
                    <a href="/forgot-password">メールアドレスもしくはパスワードをお忘れの方はこちら</a>
                  </fieldset>
                </form>
              </div>
            </div>
          </section>
        </article>
      </main>
    </div>
  );
}

export default AdminSignIn;
