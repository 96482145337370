import styled from "styled-components";

const SContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PcAccessRestrictions = () => {
  return(
    <SContainer>
      <p className="text-center">本サービスは現在スマートフォンでの<br />視聴に最適化されております。<br />ご迷惑をお掛け致しますが<br />スマートフォンからご利用ください。</p>
    </SContainer>
  );
}

export default PcAccessRestrictions;