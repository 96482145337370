import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { formatDate } from "../utils/index";
import { AuthContext } from "../App";
import { weightNumber, weightFloatNumber } from "../constants/index";

import todoWeight from "../assets/images/kv-todo-wight.jpg";
import arrowBack from "../assets/images/arrow-back.svg";
import arrow from "../assets/images/check.svg";
import { ResponseWeightInfo, WeightInfoParams } from "../interfaces/weight_info";
import { createWeightInfo, getWeightInfo, updateWeightInfo } from "../services/weight_info";
import AlertMessage from "../components/utils/AlertMessage";
import { AppDispatch, RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../components/ Loading";

const WeightInfo = () => {
  const [today, setToday] = useState<string>('');
  const [number, setNumber] = useState<string>('50');
  const [weightInfo, setWeightInfo] = useState<ResponseWeightInfo>();
  const [flootNumber, setFloatNumber] = useState<string>('50');
  const [isActive, setIsActive] = useState<boolean>(false);
  const { currentUser, currentTodo, alert, setAlert } = useContext(AuthContext);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const queryParameter = searchParams.get('date');

  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();

  const getTodayWeightInfo = async () => {
    dispatch(startLoading());
    try {
      const res = await getWeightInfo(currentTodo?.weightInfoId ?? 0);
      setWeightInfo(res.data);
      const figure = res.data.figures;
      setNumber(String(Math.floor(figure)));
      setFloatNumber(String(figure).split(".")[1]);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  const handleWeightInfoSubmit = async () => {
    const figures: number = parseFloat(number + '.' + flootNumber);
    const confirm = window.confirm(`体重: ${figures}kg \nこのデータを送信します。\n本当によろしいですか？`);
    if (!confirm) return;

    if (figures == 0) return setAlert({ ...alert, open: true, severity: 'error', message: '体重は0で登録できません。' });

    const params: WeightInfoParams = {
      userId: currentUser?.id ?? 0,
      figures: figures,
      // TODO formatDateに引数typeを持たせて、返ってくるフォーマットを変更する修正
      date: queryParameter ? queryParameter : formatDate(new Date).replaceAll('.', '-'),
      designatedDate: queryParameter ? queryParameter : '',
    }
    dispatch(startLoading());
    try {
      const res = await createWeightInfo(params);
      if (res.status === 200) {
        setAlert({ ...alert, open: true, severity: 'success', message: '体重の登録に成功しました。' });
        queryParameter ? navigate(`/todo?date=${queryParameter}`) : navigate('/todo');
      } else {
        setAlert({ ...alert, open: true, severity: 'error', message: '体重の登録に失敗しました。' });
      }
    } catch (e) {
      setAlert({ ...alert, open: true, severity: 'error', message: `体重の登録に失敗しました。${e}` });
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  const handleUpdateWeightInfo = async (id: number) => {
    let figures: number = 0;
    if (flootNumber.length && flootNumber.length === 1) {
      figures = parseFloat(number + '.' + `0${flootNumber}`);
    } else {
      figures = parseFloat(number + '.' + flootNumber);
    }
    const confirm = window.confirm(`体重: ${figures}kg \nこのデータで更新します。\n本当によろしいですか？`);
    if (!confirm) return;
    if (figures == 0) {
      setAlert({ ...alert, open: true, severity: 'error', message: '体重は0で更新できません。' });
      return;
    }

    const params: WeightInfoParams = {
      userId: currentUser?.id ?? 0,
      figures: figures,
      date: queryParameter ? queryParameter : formatDate(new Date).replaceAll('.', '-'),
      designatedDate: queryParameter ? queryParameter : ''
    }

    dispatch(startLoading());

    try {
      const updateRes = await updateWeightInfo(params, id);
      if (updateRes.status === 200) {
        setIsActive(false);
        setAlert({ ...alert, open: true, severity: 'success', message: '体重の更新に成功しました。' })
        queryParameter ? navigate(`/todo?date=${queryParameter}`) : navigate('/todo');
      }
    } catch (e) {
      setAlert({ ...alert, open: true, severity: 'error', message: '体重の更新に失敗しました。' })
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  useEffect(() => {
    setToday(formatDate(new Date()));
    if (currentTodo?.weightInfoId) {
      getTodayWeightInfo();
    }
  }, []);

  return (
    <div>
      {isLoading ? <LoadingComponent /> : (
        <div className="wrapper">
          <AlertMessage
            open={alert?.open ?? false}
            severity={alert?.severity ?? ''}
            message={alert?.message ?? ''}
          />
          <main className="page-todo-level01 page-todo-input">
            <article>
              <section className={currentTodo?.weightInfoId ? "page-heading shadow todo-completed" : "page-heading shadow"}>
                <div className="l-inner head-navi flex f-space a-center">
                  <a href={queryParameter ? `/todo?date=${queryParameter}` : "/todo"} className="btn-back">
                    <figure><img src={arrowBack} /></figure>
                  </a>
                  <div className="checking">
                    <figure><img src={arrow} /></figure>
                  </div>
                </div>
                <div className="m-inner">
                  <div className="sub-info">
                    <p className="cat">{queryParameter ? queryParameter.replaceAll('-', '.') : today}</p>
                    <h2>今日の体重を入力</h2>
                  </div>
                </div>
                <div className="bg bg-cover" style={{ backgroundImage: `url(${todoWeight})` }}></div>
              </section>

              <section className="contents">
                <div className="l-inner">
                  <div className="overview border shadow">
                    <div className="text">
                      <div className="m-inner">
                        <p>今日の体重を入力しましょう！<br />※体重はなるべく○○○な時に、測るようにしよう。</p>
                      </div>
                    </div>
                    <div className="input-content">
                      <div className="m-inner">
                        {currentTodo?.weightInfoId ? (
                          <div>
                            <fieldset>
                              <label>今日の体重</label>
                              <ul className="flex f-space a-end">
                                <li className="">
                                  {isActive ? (
                                    <select className="border-s" value={Number(number)} onChange={((e) => setNumber(e.target.value))}>
                                      {weightNumber.map((number) => (
                                        <option value={number} key={number} >{number}</option>
                                      ))}
                                    </select>
                                  ) : (
                                    <input className="select-input" value={number} disabled />
                                  )}
                                </li>
                                <li className="unit"><span>.</span></li>
                                <li className="">
                                  {isActive ? (
                                    <select className="border-s" value={Number(flootNumber)} onChange={((e) => setFloatNumber(e.target.value))}>
                                      {weightFloatNumber.map((flootNum) => (
                                        <option value={flootNum} key={flootNum}>{flootNum}</option>
                                      ))}
                                    </select>
                                  ) : (
                                    <input className="select-input" value={flootNumber} disabled />
                                  )}
                                </li>
                                <li className="unit">kg</li>
                              </ul>
                            </fieldset>
                            <fieldset>
                              <div className="submit-box">
                                {isActive ? (
                                  <div className="flex">
                                    <button type="submit" onClick={() => setIsActive(!isActive)} className="bg-gradient border-s shadow-blue">キャンセル</button>
                                    <button type="submit" onClick={() => handleUpdateWeightInfo(weightInfo?.id ?? 0)} className="bg-gradient border-s shadow-blue ml-3">更新する</button>
                                  </div>
                                ) : (
                                  <button type="submit" onClick={() => setIsActive(!isActive)} className="bg-gradient border-s shadow-blue">編集する</button>
                                )}
                              </div>
                            </fieldset>
                          </div>
                        ) : (
                          <div>
                            <fieldset>
                              <label>体重の入力</label>
                              <ul className="flex f-space a-end">
                                <li className="select-box">
                                  <select className="border-s" onChange={((e) => setNumber(e.target.value))}>
                                    {/* <option value="00">00</option> */}
                                    {weightNumber.map((number) => (
                                      <option value={number} key={number} selected={number === 50 ? true : false}>{number}</option>
                                    ))}
                                  </select>
                                </li>
                                <li className="unit"><span>.</span></li>
                                <li className="select-box">
                                  <select className="border-s" onChange={((e) => setFloatNumber(e.target.value))}>
                                    {/* <option value="00">00</option> */}
                                    {weightFloatNumber.map((flootNum) => (
                                      <option value={flootNum} key={flootNum} selected={flootNum === 50 ? true : false}>{flootNum}</option>
                                    ))}
                                  </select>
                                </li>
                                <li className="unit">kg</li>
                              </ul>
                            </fieldset>
                            <fieldset>
                              <div className="submit-box">
                                <button onClick={handleWeightInfoSubmit} type="submit" className="bg-gradient border-s shadow-blue">送信する</button>
                              </div>
                            </fieldset>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </main>
        </div>
      )}
    </div>

  );
}

export default WeightInfo;
