import { useContext, useEffect, useState } from "react";

import { WorkoutMovieInterface } from "../interfaces/workout";
import { useParams } from "react-router-dom";

import play from "../assets/images/play.svg";
import arrowBack from "../assets/images/arrow-back-blk.svg";
import check from "../assets/images/check.svg";
import tag from "../assets/images/tag.svg"
import ornamentHeading from "../assets/images/ornament-heading.svg"
import arrow from "../assets/images/arrow.svg";
import { createServiceCompleteWorkoutMovie, getServiceWorkoutMovie, getServiceWorkoutMovieLastId } from "../services/workouts";
import { formatDate, formatMovieTime } from "../utils";
import { AuthContext } from "../App";
import { RequestCompleteParams } from "../interfaces/complete";
import { getCompleteToToday } from "../services/complete";
import { RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";

const WorkoutMovie = () => {
  const [workoutMovie, setWorkoutMovie] = useState<WorkoutMovieInterface>();
  const [time, setTime] = useState<string>('');
  const [today, setToday] = useState<string>('');
  const [workoutMovieLastId, setWorkoutMovieLastId] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const params = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const queryParameter = searchParams.get('date');
  const { currentUser } = useContext(AuthContext);
  const completeParams: RequestCompleteParams = {
    userId: currentUser?.id ?? 0,
    kind: 'WorkoutMovies',
    kindId: Number(params.workout_movie_id),
    date: queryParameter ? queryParameter : today.replaceAll('.', '-')
  }

  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();


  var videoElem = document.getElementById('workout-video') as HTMLMediaElement;
  // メタ情報の読み込みが完了したときに発火。
  videoElem?.addEventListener('loadedmetadata', function () {
    const totalPlayTime = videoElem.duration;
    setTime(formatMovieTime(totalPlayTime));
  })

  const createComplete = async () => {
    try {
      const completeRes = await createServiceCompleteWorkoutMovie(completeParams);
      if (completeRes.status === 200) {
        window.location.reload()
        console.log('成功')
      } else {
        console.log('すでに完了してます')
      }
    } catch (e) {
      console.log(e);
    }
  }


  const getWorkoutMovie = async () => {
    try {
      const res = await getServiceWorkoutMovie(Number(params.workout_id), Number(params.workout_movie_id));
      setWorkoutMovie(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  const getWorkoutMovieLastId = async () => {
    try {
      const workoutMovieLastIdRes = await getServiceWorkoutMovieLastId(Number(params.workout_id));
      setWorkoutMovieLastId(workoutMovieLastIdRes);
    } catch (e) {
      console.log(e);
    }
  }

  const getComplete = async () => {
    try {
      const completeRes = queryParameter ?? '' ? await getCompleteToToday(completeParams, queryParameter ?? '') : await getCompleteToToday(completeParams);
      // TODO 存在しませんで判定しているのを修正する
      if (completeRes.data !== "存在しません") {
        setIsComplete(true);
      } else {
        setIsComplete(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const hrefSum = (hrefId: number) => hrefId + 1;

  getCompleteToToday(completeParams);

  const alertNotion = () => {
    alert("動画を最後まで見るとToDoが完了状態となり、「次の動画へ」ボタンがアクティブになります。");
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      await getWorkoutMovie();
      setToday(formatDate(new Date()));
      await getComplete();
      await getWorkoutMovieLastId();
      dispatch(stopLoading());
    };
    fetchData();
  }, []);

  return (
    <div className="wrapper">
      {isComplete ? (
        <a href={Number(params.workout_movie_id) !== workoutMovieLastId ? queryParameter ? "/workouts/" + params.workout_id + "/workout_movie/" + hrefSum(Number(params.workout_movie_id)) + `?date=${queryParameter}` : "/workouts/" + params.workout_id + "/workout_movie/" + hrefSum(Number(params.workout_movie_id)) : '/todo'} className={isComplete ? "foot-next-btn" : "foot-next-btn link-disabled"}>
          <span>{Number(params.workout_movie_id) === workoutMovieLastId ? "TODOページへ" : "次の動画へ"}</span>
          <figure><img src={play} alt="play img" /></figure>
        </a>
      ) : (
        <a tabIndex={-1} className={isComplete ? "foot-next-btn" : "foot-next-btn link-disabled"} onClick={isComplete || alertNotion}>
          <span>次の動画へ</span>
          <figure><img src={play} alt="play img" /></figure>
        </a>
      )}

      <main className="page-todo-level02 page-workout-detail">
        <article>

          <section className={isComplete ? "page-heading todo-completed" : "page-heading"}>
            <div className="l-inner head-navi flex f-space a-center">
              <a href={queryParameter ? "/workouts/" + params.workout_id + `?date=${queryParameter}` : "/workouts/" + params.workout_id} className="btn-back">
                <figure><img src={arrowBack} /></figure>
              </a>
              <div className="checking">
                <figure><img src={check} /></figure>
              </div>
            </div>
            <video src={workoutMovie?.movieUrl} poster={workoutMovie?.thumbnailPath} controls onEnded={createComplete} id="workout-video" className="video"></video>
            <div className="page-title">
              <div className="l-inner">
                <h2>{workoutMovie?.title}</h2>
                <div className="sub-info flex a-center">
                  {/* ストレッチが固定値になっているため、共有もらい次第動的に表示する */}
                  {/* <p className="cat">ストレッチ</p>
                  <span></span> */}
                  <p className="time">{time}</p>
                </div>
                <ul className="tags flex f-start f-wrap">
                  {workoutMovie?.efficacies.map((efficacy) => (
                    <li key={efficacy.id} className="flex f-space a-center border-s">
                      <figure><img src={tag} /></figure>
                      <p>{efficacy.name}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>

          <section className="contents">
            <div className="l-inner">
              <ul className="pull-content">
                {workoutMovie?.workoutMovieContents.map((workoutMovieContent) => (
                  <li className="border">
                    <div className="title">
                      <div className="title-head flex f-start a-center">
                        <figure><img src={ornamentHeading} /></figure>
                        <h3>{workoutMovieContent.title}</h3>
                      </div>
                      <figure className="btn"><img src={arrow} /></figure>
                    </div>
                    <div className="text active">
                      {workoutMovieContent.content.split(/(\n)/).map((content, index) => {
                        return (
                          <p key={index}>{content}</p>
                        );
                      })}
                    </div>
                  </li>
                ))}
              </ul>
              {/* <script type="text/javascript">
                  $('.pull-content li .title').on('click', function(){
                      $('.title + .text').toggleClassName('active');
                      $(this).toggleClassName('active');
                  });
              </script> */}
            </div>
          </section>
        </article>
      </main>
    </div>
  );
}

export default WorkoutMovie;
