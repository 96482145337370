export function getWeek(date: Date): number {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getMonth(date: Date): number {
  return date.getMonth() + 1;  // 月は0から始まるので1を加えます
}

export function convertToJapaneseBase24(date: Date, isFallingAsleepTime: boolean) {
  if (isFallingAsleepTime) {
    date.setHours(date.getHours() + 24);
  } else {
    date.setHours(date.getHours());
  }
  return date.getHours() + date.getMinutes() / 60;
}

export function getCurrentWeekNumber() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // getMonthは0から始まるので1を加算
  const day = now.getDate();

  // その月の1日の曜日を取得 (0: Sunday, 1: Monday, ..., 6: Saturday)
  let firstDayOfMonth = new Date(year, month - 1, 1).getDay();

  // 週の番号を計算
  let weekNumber = Math.ceil((day + firstDayOfMonth) / 7);

  return weekNumber;
}

export function convertToHours(hoursDecimal: number) {
  const intHours = Math.floor(hoursDecimal);
  return intHours;
}

export function convertToMinutes(hoursDecimal: number) {
  const intHours = Math.floor(hoursDecimal);
  const minutesDecimal = (hoursDecimal - intHours) * 60;
  const intMinutes = Math.floor(minutesDecimal);

  return intMinutes;
}

