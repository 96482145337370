export const formatDate = (d: Date, type="display") => {
  const year: number = d.getFullYear();
  const month: string = ("00" + (d.getMonth() + 1)).slice(-2);
  const day: string = ("00" + d.getDate()).slice(-2);
  return `${year + "." + month + "." + day}`;
}

export const formatMovieTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export const getTotalTime = (playTimes: Array<string>) => {
  let totalSeconds = 0;
  playTimes.forEach((playTime) => {
    totalSeconds += changeToSeconds(playTime);
  })
  const newMinutes = secondsToMinutes(totalSeconds);
  return newMinutes + 1;
}

// 動画の再生時間を秒数に変換
const changeToSeconds = (time: string) => {
  if (time) {
    const splitTime = time.split(':');
    const minutes = Number(splitTime[0]);
    const seconds = Number(splitTime[1]);
    const newSeconds = seconds + minutes * 60;
    return newSeconds;
  } else {
    return 0;
  }
}

const secondsToMinutes = (seconds: number) => {
  const newMinutes = Math.floor(seconds % 3600 / 60);
  return newMinutes;
}