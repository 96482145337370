import AdminLayout from "../../layouts/admin"

const AdminTop = () => {
  return(
    <AdminLayout>
      <div>管理画面トップ</div>
    </AdminLayout> 
  );
}

export default AdminTop;
