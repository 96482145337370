import Cookies from "js-cookie";
import client from "./client"
import { User, ResponseUser } from "../interfaces/auth";

export const getUsers = async () => {
  return await client.get('/admin/users', {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    },
  });
};

export const getUser = async (id: number) => {
  return await client.get(`/admin/users/${id}`, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    },
  });
};

export const getPreUser = async () => {
  return await client.get('/admin/pre_users', {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    },
  });
};

export const invitePreUser = async (id: number) => {
  return await client.post(`/admin/pre_users/invite/${id}`, {}, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    },
  });
};

export const inviteMultiplePreUser = async () => {
  return await client.post(`/admin/pre_users/mlutiple_invite`, {}, {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    },
  });
};