import Header from '../components/Header';
import { useContext, useEffect, useState } from 'react';
import Graph from '../components/Graph';
import { getWeightInfosByUserID } from "../services/weight_info";
import WeightInfo from '../entities/weight_info';
import { formatDate } from '@fullcalendar/core';
import { getSleepInfosByUserID } from '../services/sleep_info';
import SleepInfo from '../entities/sleep_info';
import { convertToHours, convertToJapaneseBase24, convertToMinutes, getCurrentWeekNumber, getMonth, getWeek } from '../utils/date';

import dataActive from "../assets/images/data-active.svg";
import todoInActive from "../assets/images/todo-inactive.svg";
import activeInActive from "../assets/images/account-inactive.svg";
import { getWeeklyLabel } from '../utils/weekly_label';
import { getMonthlyLabel } from '../utils/monthly_label';
import { AuthContext } from '../App';
import { AppDispatch, RootState } from "../store/store";
import { startLoading, stopLoading } from "../slice/loading_slice";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../components/ Loading";

const Data: React.FC = () => {
  const weightGraphSwitch = useState<string>('daily');
  const sleepTimeGraphSwitch = useState<string>('daily');
  const fallingAsleepTimeGraphSwitch = useState<string>('daily');
  const gettingUpTimeGraphSwitch = useState<string>('daily');
  const [allWeightInfos, setAllWeightInfos] = useState<Array<WeightInfo>>();
  const [todayWightInfo, setTodayWightInfo] = useState<WeightInfo>();
  const [weeklyWightAverages, setWeeklyWightAverages] = useState<Array<number>>([]);
  const [monthlyWightAverages, setMonthlyWightAverages] = useState<Array<number>>([]);
  const [allSleepInfos, setAllSleepInfos] = useState<Array<SleepInfo>>();
  const [weeklySleepingTimeAverages, setWeeklySleepingTimeAverages] = useState<Array<number>>([]);
  const [monthlySleepingTimeAverages, setMonthlySleepingTimeAverages] = useState<Array<number>>([]);
  const [weeklyFallingAsleepTimeAverages, setWeeklyFallingAsleepTimeAverages] = useState<Array<number>>([]);
  const [monthlyFallingAsleepTimeAverages, setMonthlyFallingAsleepTimeAverages] = useState<Array<number>>([]);
  const [weeklyGettingUpTimeAverages, setWeeklyGettingUpTimeAverages] = useState<Array<number>>([]);
  const [monthlyGettingUpTimeAverages, setMonthlyGettingUpTimeAverages] = useState<Array<number>>([]);
  const { currentUser } = useContext(AuthContext);
  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();

  // 抽出するデータの種類
  enum InfoType {
    weight,
    sleepingTime,
    fallingAsleepTime,
    gettingUpTime,
  }

  // 体重データの表示に使う要素
  enum ElmType {
    today,
    comparedToLastWeek,
    comparedToLastMonth,
  }

  // 直近6週間のデータをセット
  function setWeeklyInfoFn(array: Array<any>, type: InfoType) {

    const today = new Date();
    const sixWeeksAgo = new Date(today);
    sixWeeksAgo.setDate(today.getDate() - 42); // 6週間前

    if (array === undefined) {
      return;
    }

    switch (type) {
      case InfoType.weight:
        array as Array<WeightInfo>;
        break;

      case InfoType.sleepingTime:
      case InfoType.fallingAsleepTime:
      case InfoType.gettingUpTime:
        array as Array<SleepInfo>;
        break;
    }

    // 直近6週間のデータのみ抽出
    const recentData = array?.filter((info) => {
      return new Date(info.createdAt) >= sixWeeksAgo && new Date(info.createdAt) <= today;
    });

    // 同じ週に含まれるデータの平均を計算
    let averages: number[] = [];
    let currentWeekSum = 0;
    let currentWeekCount = 0;
    let currentWeek = getWeek(sixWeeksAgo);

    for (let i = 0; i < recentData!.length; i++) {
      const dataWeek = getWeek(new Date(recentData![i].createdAt));
      if (dataWeek !== currentWeek) {
        if (currentWeekCount !== 0) {
          const toFixedNum = Number((currentWeekSum / currentWeekCount).toFixed(2));
          switch (type) {
            case InfoType.weight:
              averages.push(toFixedNum);
              break;
            case InfoType.sleepingTime:
            case InfoType.fallingAsleepTime:
            case InfoType.gettingUpTime:
              averages.push(toFixedNum);
              break;
          }
        }
        currentWeek = dataWeek;
        currentWeekSum = 0;
        currentWeekCount = 0;
      }

      switch (type) {
        case InfoType.weight:
          currentWeekSum += recentData![i].figures;
          break;

        case InfoType.sleepingTime:
          // 入眠時間と起床時間の差分から睡眠時間を算出
          const sleepStart = new Date(recentData![i].fallingAsleepTime);
          const sleepEnd = new Date(recentData![i].gettingUpTime);
          const sleepDuration = (sleepEnd.getTime() - sleepStart.getTime()) / (1000 * 60 * 60);
          currentWeekSum += sleepDuration;
          break;

        case InfoType.fallingAsleepTime:
          var fallingAsleepTime = convertToJapaneseBase24(new Date(recentData![i].fallingAsleepTime), true);
          if (fallingAsleepTime < 12) {
            fallingAsleepTime += 24;
          }
          currentWeekSum += fallingAsleepTime;
          break;

        case InfoType.gettingUpTime:
          const gettingUpTime = convertToJapaneseBase24(new Date(recentData![i].gettingUpTime), false);
          currentWeekSum += gettingUpTime;
          break;
      }
      currentWeekCount++;
    }

    if (currentWeekCount !== 0) {
      const toFixedNum = Number((currentWeekSum / currentWeekCount).toFixed(2));
      switch (type) {
        case InfoType.weight:
          averages.push(toFixedNum);
          break;
        case InfoType.sleepingTime:
        case InfoType.fallingAsleepTime:
        case InfoType.gettingUpTime:
          averages.push(toFixedNum);
          break;
      }
    }

    switch (type) {
      case InfoType.weight:
        setWeeklyWightAverages(averages);
        return;
      case InfoType.sleepingTime:
        setWeeklySleepingTimeAverages(averages);
        return;
      case InfoType.fallingAsleepTime:
        setWeeklyFallingAsleepTimeAverages(averages);
        return;
      case InfoType.gettingUpTime:
        setWeeklyGettingUpTimeAverages(averages);
        return;
    }
  }

  // 直近6ヶ月のデータをセット
  function setMonthlyInfoFn(array: Array<any>, type: InfoType) {
    const today = new Date();
    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6);  // 6ヶ月前

    if (array === undefined) {
      return;
    }

    switch (type) {
      case InfoType.weight:
        array as Array<WeightInfo>;
        break;

      case InfoType.sleepingTime:
      case InfoType.fallingAsleepTime:
      case InfoType.gettingUpTime:
        array as Array<SleepInfo>;
        break;
    }

    // 直近6ヶ月のデータのみ抽出
    const recentData = array?.filter((info) => {
      return new Date(info.createdAt) >= sixMonthsAgo && new Date(info.createdAt) <= today;
    });

    // 同じ月に含まれるデータの平均を計算
    let averages: number[] = [];
    let currentMonthSum = 0;
    let currentMonthCount = 0;
    let currentMonth = getMonth(sixMonthsAgo);

    for (let i = 0; i < recentData!.length; i++) {
      const dataMonth = getMonth(new Date(recentData![i].createdAt));

      if (dataMonth !== currentMonth) {
        if (currentMonthCount !== 0) {
          averages.push(currentMonthSum / currentMonthCount);
        }
        currentMonth = dataMonth;
        currentMonthSum = 0;
        currentMonthCount = 0;
      }

      switch (type) {
        case InfoType.weight:
          currentMonthSum += recentData![i].figures;
          break;

        case InfoType.sleepingTime:
          // 入眠時間と起床時間の差分から睡眠時間を算出
          const sleepStart = new Date(recentData![i].fallingAsleepTime);
          const sleepEnd = new Date(recentData![i].gettingUpTime);
          const sleepDuration = (sleepEnd.getTime() - sleepStart.getTime()) / (1000 * 60 * 60);
          currentMonthSum += sleepDuration;
          break;

        case InfoType.fallingAsleepTime:
          var fallingAsleepTime = convertToJapaneseBase24(new Date(recentData![i].fallingAsleepTime), true);
          if (fallingAsleepTime < 12) {
            fallingAsleepTime += 24;
          }
          currentMonthSum += fallingAsleepTime;
          break;

        case InfoType.gettingUpTime:
          const gettingUpTime = convertToJapaneseBase24(new Date(recentData![i].gettingUpTime), false);
          currentMonthSum += gettingUpTime;
          break;
      }
      currentMonthCount++;
    }

    if (currentMonthCount !== 0) {
      averages.push(currentMonthSum / currentMonthCount);
    }

    switch (type) {
      case InfoType.weight:
        setMonthlyWightAverages(averages);
        return;
      case InfoType.sleepingTime:
        setMonthlySleepingTimeAverages(averages);
        return;
      case InfoType.fallingAsleepTime:
        setMonthlyFallingAsleepTimeAverages(averages);
        return;
      case InfoType.gettingUpTime:
        setMonthlyGettingUpTimeAverages(averages);
        return;
    }
  }

  const getCurrentWeek = () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const weekNum = getCurrentWeekNumber();
    return `${year}年${month}月第${weekNum}週目`;
  }

  // ここから体重関連 //
  // 体重データを取得
  const getWeightInfos = async () => {
    dispatch(startLoading());
    try {
      const res = await getWeightInfosByUserID(currentUser?.id ?? 0);
      setAllWeightInfos(res ?? undefined)
      setTodayWeightInfoFn(res ?? undefined)
      setWeeklyInfoFn(res ?? undefined, InfoType.weight)
      setMonthlyInfoFn(res ?? undefined, InfoType.weight)
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  // 本日の体重データをセット
  function setTodayWeightInfoFn(w: Array<WeightInfo>) {
    const filteredData = w.filter((data: WeightInfo) => (new Date(data.createdAt).getMonth() === new Date().getMonth() && new Date(data.createdAt).getDate() === new Date().getDate())) ?? [];
    setTodayWightInfo(filteredData[0]);
  }

  function getFormattedWeightDifference(todayWeight: number, lastWeekWeight: number) {
    const difference = todayWeight - lastWeekWeight;
    if (difference > 0) {
      return `+${difference.toFixed(1)}`;
    }
    return difference.toFixed(1);
  }

  // 本日の体重データがあるかどうかで表示を変える
  function setWeightInfoElm(type: ElmType) {
    switch (type) {
      case ElmType.today:
        return todayWightInfo ? (
          <div className="value">{todayWightInfo?.figures}<span className="value-kg">kg</span></div>
        ) : <div className="value">-- <span className="value-kg">kg</span></div>

      case ElmType.comparedToLastWeek:
        if (todayWightInfo && weeklyWightAverages.length > 1) {
          if ((todayWightInfo?.figures - weeklyWightAverages[weeklyWightAverages.length - 2]) > 0) {
            return <div className="week-value plus">{getFormattedWeightDifference(todayWightInfo?.figures, weeklyWightAverages[weeklyWightAverages.length - 2])}<span className="week-and-month-value-kg">kg</span></div>
          } else {
            return <div className="week-value minus">{getFormattedWeightDifference(todayWightInfo?.figures, weeklyWightAverages[weeklyWightAverages.length - 2])}<span className="week-and-month-value-kg">kg</span></div>
          }
        } else {
          return <div className="week-value">-- <span className="week-and-month-value-kg">kg</span></div>
        }

      case ElmType.comparedToLastMonth:
        if (todayWightInfo && monthlyWightAverages.length > 1) {
          if ((todayWightInfo?.figures - monthlyWightAverages[monthlyWightAverages.length - 2]) > 0) {
            return <div className="month-value plus">{getFormattedWeightDifference(todayWightInfo?.figures, monthlyWightAverages[monthlyWightAverages.length - 2])}<span className="week-and-month-value-kg">kg</span></div>
          } else {
            return <div className="month-value minus">{getFormattedWeightDifference(todayWightInfo?.figures, monthlyWightAverages[monthlyWightAverages.length - 2])}<span className="week-and-month-value-kg">kg</span></div>
          }
        } else {
          return <div className="month-value">-- <span className="week-and-month-value-kg">kg</span></div>
        }
    }
  }

  // 体重グラフセット
  function setWightGraph() {
    const type = weightGraphSwitch[0];
    if (type === 'daily') {
      const data = {
        // 配列の一番後ろから6つ分の日付データを取得
        labels: allWeightInfos?.slice(-6).map((data: WeightInfo) => formatDate(new Date(data.date))) ?? [],
        title: '日毎体重',
        data: allWeightInfos?.slice(-6).map((data: WeightInfo) => data.figures) ?? [],
        isWeight: true,
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'weekly') {
      const data = {
        labels: getWeeklyLabel(weeklyWightAverages),
        title: '週毎体重',
        // 本日を基準に、配列の一番後ろから６週分の体重データを取得
        data: weeklyWightAverages,
        isWeight: true,
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'monthly') {
      const data = {
        labels: getMonthlyLabel(monthlyWightAverages),
        title: '月毎体重',
        data: monthlyWightAverages,
        isWeight: true,
      }
      return (
        <Graph graphData={data} />
      )
    }
  }

  // 体重グラフの切り替え
  const changeWightGraph = (type: string) => {
    weightGraphSwitch[1](type);
  }
  // ここまで体重関連 //


  // ここから睡眠関連 //
  // 睡眠データを取得
  const getSleepInfos = async () => {
    dispatch(startLoading());
    try {
      const res = await getSleepInfosByUserID(currentUser?.id ?? 0);
      setAllSleepInfos(res)
      setWeeklyInfoFn(res ?? undefined, InfoType.sleepingTime)
      setWeeklyInfoFn(res ?? undefined, InfoType.fallingAsleepTime)
      setWeeklyInfoFn(res ?? undefined, InfoType.gettingUpTime)
      setMonthlyInfoFn(res ?? undefined, InfoType.sleepingTime)
      setMonthlyInfoFn(res ?? undefined, InfoType.fallingAsleepTime)
      setMonthlyInfoFn(res ?? undefined, InfoType.gettingUpTime)
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(stopLoading());
    }
  }

  // 睡眠データがあるかどうかで表示を変える
  function setSleepInfoElm(infoType: InfoType, elmType: ElmType) {
    switch (infoType) {
      case InfoType.sleepingTime:
        if (elmType === ElmType.today) {
          return weeklySleepingTimeAverages.length === 0 ? (
            <div className="time">--<span className="time-label">時間</span>--<span className="time-label">分</span></div>
          ) : (
            <div className="time">{(convertToHours(weeklySleepingTimeAverages[weeklySleepingTimeAverages.length - 1]))}<span className="time-label">時間</span>{(convertToMinutes(weeklySleepingTimeAverages[weeklySleepingTimeAverages.length - 1]))}<span className="time-label">分</span></div>
          )
        } else {
          return weeklySleepingTimeAverages.length > 1 ? (
            <div className="time">{(convertToHours(weeklySleepingTimeAverages[0]))}<span className="time-label">時間</span>{(convertToMinutes(weeklySleepingTimeAverages[0]))}<span className="time-label">分</span></div>
          ) : (
            <div className="time">--<span className="time-label">時間</span>--<span className="time-label">分</span></div>
          )
        }

      case InfoType.fallingAsleepTime:
        if (elmType === ElmType.today) {
          return weeklyFallingAsleepTimeAverages.length === 0 ? (
            <div className="time">--<span className="time-label">時</span>--<span className="time-label">分</span></div>
          ) : (
            <div className="time">{(convertToHours(weeklyFallingAsleepTimeAverages[weeklyFallingAsleepTimeAverages.length - 1]))}<span className="time-label">時</span>{(convertToMinutes(weeklyFallingAsleepTimeAverages[weeklyFallingAsleepTimeAverages.length - 1]))}<span className="time-label">分</span></div>
          )
        } else {
          return weeklyFallingAsleepTimeAverages.length > 1 ? (
            <div className="time">{(convertToHours(weeklyFallingAsleepTimeAverages[0]))}<span className="time-label">時</span>{(convertToMinutes(weeklyFallingAsleepTimeAverages[0]))}<span className="time-label">分</span></div>
          ) : (
            <div className="time">--<span className="time-label">時</span>--<span className="time-label">分</span></div>
          )
        }

      case InfoType.gettingUpTime:
        if (elmType === ElmType.today) {
          return weeklyGettingUpTimeAverages.length === 0 ? (
            <div className="time">--<span className="time-label">時</span>--<span className="time-label">分</span></div>
          ) : (
            <div className="time">{(convertToHours(weeklyGettingUpTimeAverages[weeklyGettingUpTimeAverages.length - 1]))}<span className="time-label">時</span>{(convertToMinutes(weeklyGettingUpTimeAverages[weeklyGettingUpTimeAverages.length - 1]))}<span className="time-label">分</span></div>
          )
        } else {
          return weeklyGettingUpTimeAverages.length > 1 ? (
            <div className="time">{(convertToHours(weeklyGettingUpTimeAverages[0]))}<span className="time-label">時</span>{(convertToMinutes(weeklyGettingUpTimeAverages[0]))}<span className="time-label">分</span></div>
          ) : (
            <div className="time">--<span className="time-label">時</span>--<span className="time-label">分</span></div>
          )
        }
    }
  }

  // 睡眠時間グラフセット
  function setSleepingTimeGraph() {
    const type = sleepTimeGraphSwitch[0];

    if (type === 'daily') {
      const data = {
        labels: allSleepInfos?.slice(-6).map((data: SleepInfo) => formatDate(new Date(data.date))) ?? [],
        title: '日毎睡眠時間',
        data: allSleepInfos?.slice(-6).map((data: SleepInfo) => (new Date(data.gettingUpTime).getTime() - new Date(data.fallingAsleepTime).getTime()) / (1000 * 60 * 60)) ?? [],
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'weekly') {
      const data = {
        labels: getWeeklyLabel(weeklySleepingTimeAverages),
        title: '週毎睡眠時間',
        data: weeklySleepingTimeAverages,
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'monthly') {
      const data = {
        labels: getMonthlyLabel(monthlySleepingTimeAverages),
        title: '月毎睡眠時間',
        data: monthlySleepingTimeAverages,
      }
      return (
        <Graph graphData={data} />
      )
    }
  }

  // 入眠時間グラフセット
  function setFallingAsleepTimeGraph() {
    const type = fallingAsleepTimeGraphSwitch[0];
    if (type === 'daily') {
      const data = {
        labels: allSleepInfos?.slice(-6).map((data: SleepInfo) => formatDate(new Date(data.date))) ?? [],
        title: '日毎入眠時間',
        data: allSleepInfos?.slice(-6).map((data: SleepInfo) => convertToJapaneseBase24(new Date(data.fallingAsleepTime), true)) ?? [],
        isDate: true,
        isFallingASleepTime: true,
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'weekly') {
      const data = {
        labels: getWeeklyLabel(weeklyFallingAsleepTimeAverages),
        title: '週毎入眠時間',
        data: weeklyFallingAsleepTimeAverages,
        isDate: true,
        isFallingASleepTime: true,
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'monthly') {
      const data = {
        labels: getMonthlyLabel(monthlyFallingAsleepTimeAverages),
        title: '月毎入眠時間',
        data: monthlyFallingAsleepTimeAverages,
        isDate: true,
        isFallingASleepTime: true,
      }
      return (
        <Graph graphData={data} />
      )
    }
  }

  // 起床時間グラフセット
  function setGettingUpTimeGraph() {
    const type = gettingUpTimeGraphSwitch[0];
    if (type === 'daily') {
      const data = {
        labels: allSleepInfos?.slice(-6).map((data: SleepInfo) => formatDate(new Date(data.date))) ?? [],
        title: '日毎起床時間',
        data: allSleepInfos?.slice(-6).map((data: SleepInfo) => convertToJapaneseBase24(new Date(data.gettingUpTime), false)) ?? [],
        isDate: true,
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'weekly') {
      const data = {
        labels: getWeeklyLabel(weeklyGettingUpTimeAverages),
        title: '週毎起床時間',
        data: weeklyGettingUpTimeAverages,
        isDate: true,
      }
      return (
        <Graph graphData={data} />
      )
    } else if (type === 'monthly') {
      const data = {
        labels: getMonthlyLabel(monthlyGettingUpTimeAverages),
        title: '月毎起床時間',
        data: monthlyGettingUpTimeAverages,
        isDate: true,
      }
      return (
        <Graph graphData={data} />
      )
    }
  }

  // 睡眠グラフの切り替え
  const changeSleepingTimeGraph = (type: string) => {
    sleepTimeGraphSwitch[1](type);
  }

  // 入眠グラフの切り替え
  const changeFallingAsleepTimeGraph = (type: string) => {
    fallingAsleepTimeGraphSwitch[1](type);
  }

  // 起床グラフの切り替え
  const changeGettingUpTimeGraph = (type: string) => {
    gettingUpTimeGraphSwitch[1](type);
  }

  // ここまで睡眠関連 //

  useEffect(() => {
    getWeightInfos();
    getSleepInfos();
  }, []);

  return (
    <div>
      {isLoading ? <LoadingComponent /> : (
        <div className="wrapper">
          <Header />
          <nav className="foot-common-menu">
            <ul className="flex a-center">
              <li>
                <a href="/todo">
                  <figure className="inactive-icon"><img src={todoInActive} /></figure>
                  <span>デイリーToDo</span>
                </a>
              </li>
              <li className="active">
                <a href="/data">
                  <figure className="active-icon"><img src={dataActive} /></figure>
                  <span>データ</span>
                </a>
              </li>
              <li>
                <a href="/">
                  <figure className="inactive-icon"><img src={activeInActive} /></figure>
                  <span>アカウント</span>
                </a>
              </li>
            </ul>
          </nav>
          <main className="page-data">
            <section className="data-contents">
              <div className="l-inner">
                <ul>
                  <li className="col2 border shadow">
                    <div className="data-container">
                      <div className="title">体重データ</div>
                      <div className="flex a-end data-box">
                        <div>
                          {setWeightInfoElm(ElmType.today)}
                          <div className="date">{formatDate(new Date())}</div>
                        </div>
                        <div>
                          {setWeightInfoElm(ElmType.comparedToLastWeek)}
                          <div className="week-and-month-title">先週比</div>
                        </div>
                        <div>
                          {setWeightInfoElm(ElmType.comparedToLastMonth)}
                          <div className="week-and-month-title">先月比</div>
                        </div>
                      </div>
                    </div>

                    <div className="graph-container">
                      <div className="title">体重推移</div>
                      <div className="flex data-box">
                        <div className={weightGraphSwitch[0] === 'daily' ? 'button active' : 'button'} onClick={() => changeWightGraph('daily')}>日毎</div>
                        <div className={weightGraphSwitch[0] === 'weekly' ? 'button active' : 'button'} onClick={() => changeWightGraph('weekly')}>週毎</div>
                        <div className={weightGraphSwitch[0] === 'monthly' ? 'button active' : 'button'} onClick={() => changeWightGraph('monthly')}>月毎</div>
                      </div>
                      {setWightGraph()}
                    </div>
                  </li>
                  <li className="col2 border shadow">
                    <div className="data-container">
                      <div className="title-box flex">
                        <div className="title">睡眠データ - </div>
                        <div className="title">{getCurrentWeek()}</div>
                      </div>
                      <div className="data-card-container">
                        <div className="data-card flex a-end">
                          <div className="data-card-left">
                            {setSleepInfoElm(InfoType.sleepingTime, ElmType.today)}
                            <div className="time-title">今週の平均睡眠時間</div>
                          </div>
                          <div className="data-card-right">
                            {setSleepInfoElm(InfoType.sleepingTime, ElmType.comparedToLastWeek)}
                            <div className="time-title">先週平均</div>
                          </div>
                        </div>

                        <div className="data-card flex a-end">
                          <div className="data-card-left">
                            {setSleepInfoElm(InfoType.fallingAsleepTime, ElmType.today)}
                            <div className="time-title">今週の平均入眠時間</div>
                          </div>
                          <div className="data-card-right">
                            {setSleepInfoElm(InfoType.fallingAsleepTime, ElmType.comparedToLastWeek)}
                            <div className="time-title">先週平均</div>
                          </div>
                        </div>

                        <div className="data-card flex a-end">
                          <div className="data-card-left">
                            {setSleepInfoElm(InfoType.gettingUpTime, ElmType.today)}
                            <div className="time-title">今週の平均起床時間</div>
                          </div>
                          <div className="data-card-right">
                            {setSleepInfoElm(InfoType.gettingUpTime, ElmType.comparedToLastWeek)}
                            <div className="time-title">先週平均</div>
                          </div>
                        </div>
                      </div>


                    </div>

                    <div className="graph-container">
                      <div className="title">睡眠データ推移</div>
                      <div className="title">睡眠時間推移</div>
                      <div className="flex data-box">
                        <div className={sleepTimeGraphSwitch[0] === 'daily' ? 'button active' : 'button'} onClick={() => changeSleepingTimeGraph('daily')}>日毎</div>
                        <div className={sleepTimeGraphSwitch[0] === 'weekly' ? 'button active' : 'button'} onClick={() => changeSleepingTimeGraph('weekly')}>週毎</div>
                        <div className={sleepTimeGraphSwitch[0] === 'monthly' ? 'button active' : 'button'} onClick={() => changeSleepingTimeGraph('monthly')}>月毎</div>
                      </div>
                      {setSleepingTimeGraph()}
                    </div>

                    <div className="graph-container">
                      <div className="title">入眠時間推移</div>
                      <div className="flex data-box">
                        <div className={fallingAsleepTimeGraphSwitch[0] === 'daily' ? 'button active' : 'button'} onClick={() => changeFallingAsleepTimeGraph('daily')}>日毎</div>
                        <div className={fallingAsleepTimeGraphSwitch[0] === 'weekly' ? 'button active' : 'button'} onClick={() => changeFallingAsleepTimeGraph('weekly')}>週毎</div>
                        <div className={fallingAsleepTimeGraphSwitch[0] === 'monthly' ? 'button active' : 'button'} onClick={() => changeFallingAsleepTimeGraph('monthly')}>月毎</div>
                      </div>
                      {setFallingAsleepTimeGraph()}
                    </div>

                    <div className="graph-container">
                      <div className="title">起床時間推移</div>
                      <div className="flex data-box">
                        <div className={gettingUpTimeGraphSwitch[0] === 'daily' ? 'button active' : 'button'} onClick={() => changeGettingUpTimeGraph('daily')}>日毎</div>
                        <div className={gettingUpTimeGraphSwitch[0] === 'weekly' ? 'button active' : 'button'} onClick={() => changeGettingUpTimeGraph('weekly')}>週毎</div>
                        <div className={gettingUpTimeGraphSwitch[0] === 'monthly' ? 'button active' : 'button'} onClick={() => changeGettingUpTimeGraph('monthly')}>月毎</div>
                      </div>
                      {setGettingUpTimeGraph()}
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </main>
        </div>
      )}
    </div>
  );
}

export default Data;
