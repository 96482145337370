'use client';

import { useEffect, useState } from "react";
import AdminLayout from "../../../layouts/admin";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../../components/utils/AlertMessage";
import { AlertMessageProps } from "../../../interfaces/alert";
import { User } from "../../../interfaces/auth";
import { getUsers } from "../../../services/user";
import { formatDate } from "../../../utils";

const AdminUsers = () => {
  const [ users, setUsers ] = useState<User[]>();
  const [ alert, setAlert ] = useState<AlertMessageProps>();
  const navigate = useNavigate();

  // const handleCreateTodo = (user: User) => {
  //   return ;
  // }

  const getUserLists = async () => {
    try {
      const res = await getUsers();
      if(res.status === 200) {
        setUsers(res.data.data);
      }
    } catch (e: any) {
      setAlert({ ...alert, open: true, severity: 'error', message: e });
    }
    
  }

  useEffect(() => {
    getUserLists();
  }, []);

  return(
    <AdminLayout>
      <AlertMessage
        open={alert?.open ?? false}
        severity={alert?.severity ?? ''}
        message={alert?.message ?? ''}
      />
      <div className="pc">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                          ユーザーID
                        </th>
                        <th scope="col" className="px-6 py-3">
                          ユーザー名
                        </th>
                        <th scope="col" className="px-6 py-3">
                          メールアドレス
                        </th>
                        <th scope="col" className="px-6 py-3">
                          登録日
                        </th>
                        <th scope="col" className="px-6 py-3">
                          契約終了日
                        </th>
                        <th scope="col" className="px-6 py-3">
                          最終アクセス日
                        </th>
                        <th scope="col" className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                {users?.map((user) => (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {user.id}
                      </th>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {user.name}
                      </th>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {user.email}
                      </th>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {formatDate(new Date(user.createdAt))}
                      </th>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {user.contract_end_date ? formatDate(new Date(user.contract_end_date)) : ""}
                      </th>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {user.lastSignInAt ? formatDate(new Date(user.lastSignInAt)) : ""}
                      </th>
                      <th>
                        <a href={`/admin/users/${user.id}`} className="text-primary-10">詳細へ</a>
                      </th>
                    </tr>
                  </>
                ))}
                </tbody>
            </table>
        </div>
      </div>
      <div className="sp">
        <div className="overflow-x-scroll">
          <div className="admin-table">
          <ul className="flex my-10 admin-tr">
            <li className="fixed01 admin-th">
              ユーザーID
            </li>
            <li className="fixed01 admin-th">
              ユーザー名
            </li>
            <li className="admin-td" style={{width: "300px"}}>
              メールアドレス
            </li>
            <li className="admin-td" style={{width: "300px"}}>
              登録日
            </li>
            <li className="admin-td" style={{width: "300px"}}>
              契約終了日
            </li>
            <li className="admin-td" style={{width: "300px"}}>
              最終アクセス日
            </li>
            <li className="admin-td" style={{width: "200px"}}></li>
          </ul>
          </div>
          <div className="admin-table">
            {users?.map((user) => (
              <ul className="flex my-6 admin-tr" key={user.id}>
                <li className="fixed01 admin-th">
                  {user.id}
                </li>
                <li className="fixed01 admin-th">
                  {user.name}
                </li>
                <li className="admin-td" style={{width: "300px"}}>
                  {user.email}
                </li>
                <li className="admin-td" style={{width: "300px"}}>
                  {formatDate(new Date(user.createdAt))}
                </li>
                <li className="admin-td" style={{width: "300px"}}>
                  {user.contract_end_date ? formatDate(new Date(user.contract_end_date)) : ""}
                </li>
                <li className="admin-td" style={{width: "300px"}}>
                  {user.lastSignInAt ? formatDate(new Date(user.lastSignInAt)) : ""}
                </li>
                <li className="admin-td" style={{width: "200px"}}>
                  <a href={`/admin/users/${user.id}`} >詳細へ</a>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminUsers;
