import client from './client';
import Cookies from "js-cookie";
import { ForgotPasswordParams, Params, PasswordConfirmationParams, Headers } from "../interfaces/auth";



// サインイン
export const signIn = (params: Params) => {
  return client.post("/auth/sign_in", params);
};

// サインアップ
export const signUp = (params: Params) => {
  return client.post("/auth", params)
}

export const logout = () => {
  if(!Cookies.get('_access_token') || !Cookies.get('_client') || !Cookies.get('_uid')) return;

  return client.delete("/auth/sign_out", {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  });
}

// 認証済みのユーザーを取得
export const getCurrentUser = () => {
  if(!Cookies.get('_access_token') || !Cookies.get('_client') || !Cookies.get('_uid')) return;

  return client.get("/auth/sessions", {
    headers: {
      "access-token": Cookies.get('_access_token'),
      "client": Cookies.get('_client'),
      "uid": Cookies.get('_uid')
    }
  })
}

// パスワード再設定（メール送信）
export const forgotPassword = (params: ForgotPasswordParams) => {
  return client.post("/auth/password", params);
}

// パスワード更新
export const updatePassword = (params: PasswordConfirmationParams, uid: string, clients: string, accessToken: string) => {
  return client.put("/auth/password", params, {
    headers: {
      "uid": uid,
      "client": clients,
      "access-token": accessToken
    }
  })
}
