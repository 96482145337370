export function getMonthlyLabel(values: Array<number>) {
  const monthlyLabel = [];

  for (let i = 0; i < values.length; i++) {
    if (i === 0) {
      monthlyLabel.push("今月");
    } else if (i === 1) {
      monthlyLabel.push("先月");
    } else if (i === 2) {
      monthlyLabel.push("先々月");
    } else {
      monthlyLabel.push(`${i + 2}ヶ月前`);
    }
  }
  monthlyLabel.reverse();
  return monthlyLabel;
}


