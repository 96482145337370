import { useEffect, useState } from "react"
import { User } from "../../../interfaces/auth"
import { useParams } from "react-router-dom";
import { getUser } from "../../../services/user";
import AlertMessage from "../../../components/utils/AlertMessage";
import { AlertMessageProps } from "../../../interfaces/alert";
import AdminLayout from "../../../layouts/admin";
import { formatDate } from "../../../utils";
import DataReport from "../../../components/DataReport";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getSleepInfosByUserID } from "../../../services/sleep_info";
import { startLoading, stopLoading } from "../../../slice/loading_slice";
import LoadingComponent from "../../../components/ Loading";
import WeightInfo from "../../../entities/weight_info";
import SleepInfo from "../../../entities/sleep_info";
import { getWeightInfosByUserID } from "../../../services/weight_info";
import BreadCrumbs from "../../../components/BreadCrumbs";

const AdminUserDetail = () => {
  const [ user, setUser ] = useState<User>();
  const [ weightInfo, setWeightInfo ] = useState<WeightInfo[]>();
  const [ sleepInfo, setSleepInfo ] = useState<SleepInfo[]>();
  const isLoading = useSelector((state: RootState) => state.loading.value);
  const dispatch = useDispatch();
  const params = useParams();

  const [ alert, setAlert ] = useState<AlertMessageProps>();

  const getUserDetail = async () => {
    dispatch(startLoading());
    try {
      const res = await getUser(Number(params.id));
      if(res.status === 200) {
        await getWeightInfos(res.data.data.id);
        await getSleepInfos(res.data.data.id);
        setUser(res.data.data);
      }
    } catch (e: any) {
      setAlert({ ...alert, open: true, severity: 'error', message: e });
    } finally {
      dispatch(stopLoading());
    }
  }

  // 体重データを取得
  const getWeightInfos = async (userId: number) => {
    try {
      const res = await getWeightInfosByUserID(userId);
      setWeightInfo(res);
    } catch (e) {
      console.log(e);
    }
  }

  // 睡眠情報を取得
  const getSleepInfos = async (userId: number) => {
    try {
      const res = await getSleepInfosByUserID(userId);
      setSleepInfo(res);
    } catch (e) {
      console.log(e);
    }
  }

  const breadCrumbs = [
    { name: "会員一覧", path: "/users" }
  ];

  useEffect(() => {
    getUserDetail();
  }, []);

  return(
    <AdminLayout>
      {isLoading ? <LoadingComponent /> : (
        <>
          <BreadCrumbs lists={breadCrumbs} currentName={user?.name ?? "無記入"} />
          <div>
            <h1 className="text-xl mb-5">{user?.name ? user.name : user?.email} 様</h1>
          </div>
          <ul className="border border-black">
            <li className="flex border-b bg-white p-2">
              <p className="w-1/5">名前：</p>
              <p className="w-4/5">{user?.name}</p>
            </li>
            <li className="flex border-b bg-white p-2">
              <p className="w-1/5">メールアドレス：</p>
              <p className="w-4/5">{user?.email}</p>
            </li>
            <li className="flex border-b bg-white p-2">
              <p className="w-1/5">電話番号：</p>
              <p className="w-4/5">{user?.phoneNumber}</p>
            </li>
            <li className="flex border-b bg-white p-2">
              <p className="w-1/5">住所：</p>
              <p className="w-4/5">{user?.phoneNumber}</p>
            </li>
            <li className="flex border-b bg-white p-2">
              <p className="w-1/5">登録日：</p>
              <p className="w-4/5">{user?.createdAt ? formatDate(new Date(user.createdAt)) : ""}</p>
            </li>
            <li className="flex border-b bg-white p-2">
              <p className="w-1/5 ">契約終了日：</p>
              <p className="w-4/5">{user?.contract_end_date ? formatDate(new Date(user.contract_end_date)) : ""}</p>
            </li>
            <li className="flex border-b bg-white p-2">
              <p className="w-1/5">最終アクセス日：</p>
              <p className="w-4/5">{user?.lastSignInAt ? formatDate(new Date(user.lastSignInAt)) : ""}</p>
            </li>
          </ul>
          <div>
            <h2 className="text-xl my-5">データレポート</h2>
            { weightInfo && sleepInfo && <DataReport weightInfo={weightInfo} sleepInfo={sleepInfo} /> }
          </div>
        </>
      )}
    </AdminLayout>
  );
}

export default AdminUserDetail;
